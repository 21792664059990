/** NC SCSS Variables **/
/* BREAKPOINTS */
/* =========== */
$breakpoints: (
    4k: 3840px,
    // (3840*2160) (3440*1440)
    tv: 2560px,
    // (2560*1440)
    wide: 1920px,
    large: 1680px,
    //1680,
    default: 1440px,
    // 1366
    notebook: 1280px,
    //1280
    tablet: 1024px,
    handhelds: 800px,
    // 768, 800
    mobile: 480px,
    small: 320px,
) !default;

/** Ionic CSS Variables **/
:root {
    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;

    --ion-text-color: #000000;
    --ion-text-color-rgb: 0, 0, 0;

    --ion-color-step-50: #f2f2f2;
    --ion-color-step-100: #e6e6e6;
    --ion-color-step-150: #d9d9d9;
    --ion-color-step-200: #cccccc;
    --ion-color-step-250: #bfbfbf;
    --ion-color-step-300: #b3b3b3;
    --ion-color-step-350: #a6a6a6;
    --ion-color-step-400: #999999;
    --ion-color-step-450: #8c8c8c;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #737373;
    --ion-color-step-600: #666666;
    --ion-color-step-650: #595959;
    --ion-color-step-700: #4d4d4d;
    --ion-color-step-750: #404040;
    --ion-color-step-800: #333333;
    --ion-color-step-850: #262626;
    --ion-color-step-900: #191919;
    --ion-color-step-950: #0d0d0d;

    // Statuses active, work, passive, muted, danger etc
    --nc-color-todo: #ffc000;
    --nc-color-todo-rgb: 255, 192, 0;
    --nc-color-todo-contrast: #000000;
    --nc-color-todo-contrast-rgb: 0, 0, 0;
    --nc-color-todo-shade: #e0a900;
    --nc-color-todo-tint: #ffc61a;

    --nc-color-active: #0095fe;
    --nc-color-active-rgb: 0, 149, 254;
    --nc-color-active-contrast: #ffffff;
    --nc-color-active-contrast-rgb: 255, 255, 255;
    --nc-color-active-shade: #0083e0;
    --nc-color-active-tint: #1aa0fe;

    --nc-color-pending: #949494;
    --nc-color-pending-rgb: 148, 148, 148;
    --nc-color-pending-contrast: #000000;
    --nc-color-pending-contrast-rgb: 0, 0, 0;
    --nc-color-pending-shade: #828282;
    --nc-color-pending-tint: #9f9f9f;

    --nc-color-passive: #949494;
    --nc-color-passive-rgb: 148, 148, 148;
    --nc-color-passive-contrast: #000000;
    --nc-color-passive-contrast-rgb: 0, 0, 0;
    --nc-color-passive-shade: #828282;
    --nc-color-passive-tint: #9f9f9f;

    --nc-color-work: #53b80d;
    --nc-color-work-rgb: 83, 184, 13;
    --nc-color-work-contrast: #000000;
    --nc-color-work-contrast-rgb: 0, 0, 0;
    --nc-color-work-shade: #49a20b;
    --nc-color-work-tint: #64bf25;

    --nc-color-danger: #d8300b;
    --nc-color-danger-rgb: 216, 48, 11;
    --nc-color-danger-contrast: #ffffff;
    --nc-color-danger-contrast-rgb: 255, 255, 255;
    --nc-color-danger-shade: #be2a0a;
    --nc-color-danger-tint: #dc4523;

    --side-max-width: 340px;
    --nci-toolbar-min-height: 56px;
    --nci-paginator-height: 64px;
    --nc-map-container-height: 400px;

    --nci-card-border: none;
    --nci-card-border-radius: 0;
    --nc-tab-btn-height: 57px;
    --nci-item-padding-start: 16px;
    --nci-item-padding-end: 16px;
}

// Background color of entire app
// --ion-background-color: ;

// Background color of entire app, rgb format
// --ion-background-color-rgb: ;

// Text color of entire app
// --ion-text-color: ;

// Text color of entire app, rgb format
// --ion-text-color-rgb: ;

// Color of the Backdrop component
// --ion-backdrop-color: ;

// Background color of the overlays
// --ion-overlay-background-color: ;

// Border color
// --ion-border-color: ;

// Box shadow color
// --ion-box-shadow-color: ;

// Background of the Tab bar
// --ion-tab-bar-background: ;

// Background of the focused Tab bar
// --ion-tab-bar-background-focused: ;

// Border color of the Tab bar
// --ion-tab-bar-border-color: ;

// Color of the Tab bar
// --ion-tab-bar-color: ;

// Color of the activated Tab
// --ion-tab-bar-color-activated: ;

// Background of the Toolbar
// --ion-toolbar-background: ;

// Border color of the Toolbar
// --ion-toolbar-border-color: ;

// Color of the components in the Toolbar
// --ion-toolbar-color: ;

// Color of the activated components in the Toolbar
// --ion-toolbar-color-activated: ;

// Color of the unchecked components in the Toolbar
// --ion-toolbar-color-unchecked: ;

// Color of the checked components in the Toolbar
// --ion-toolbar-color-checked: ;

// Background of the Item
// --ion-item-background: ;

// Background of the activated Item
// --ion-item-background-activated: ;

// Border color of the Item
// --ion-item-border-color: ;

// Color of the components in the Item
// --ion-item-color: ;

// Color of the placeholder in inputs
// --ion-placeholder-color: ;

//* Application Variables *//
// Font family of the app
// --ion-font-family: '';
// Statusbar padding top of the app
// --ionatusbar-padding: ;
// Adjust the safe area inset top of the app
// --ion-safe-area-top: ;
// Adjust the safe area inset right of the app
// --ion-safe-area-right: ;
// Adjust the safe area inset bottom of the app
// --ion-safe-area-bottom: ;
// Adjust the safe area inset left of the app
// --ion-safe-area-left: ;
// Adjust the margin of the Margin attributes
// --ion-margin: ;
// Adjust the padding of the Padding attributes
// --ion-padding: ;

//* Grid Variables *//
// Number of columns in the grid
// --ion-grid-columns: ;
// Padding of the grid for xs breakpoints
// --ion-grid-padding-xs: ;
// Padding of the grid for sm breakpoints
// --ion-grid-padding-sm: ;
// Padding of the grid for md breakpoints
// --ion-grid-padding-md: ;
// Padding of the grid for lg breakpoints
// --ion-grid-padding-lg: ;
// Padding of the grid for xl breakpoints
// --ion-grid-padding-xl: ;
// Padding of the grid columns for xs breakpoints
// --ion-grid-column-padding-xs: ;
// Padding of the grid columns for sm breakpoints
// --ion-grid-column-padding-sm: ;
// Padding of the grid columns for md breakpoints
// --ion-grid-column-padding-md: ;
// Padding of the grid columns for lg breakpoints
// --ion-grid-column-padding-lg: ;
// Padding of the grid columns for xl breakpoints
// --ion-grid-column-padding-xl: ;

html.md {
    --ion-default-font: 'Lato', sans-serif;
    // --ion-text-color: #000000;
    // --ion-text-color-rgb: 0,0,0;
    // --ion-border-color: --ion-color-step-150;
}
