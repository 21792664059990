.nc-tabs {
    z-index: 1;

    &_modal {
        width: var(--side-max-width);
        height: 100vh;
        // margin-left: calc(var(--side-max-width) - 57px);
        padding-left: calc(var(--side-max-width) - 57px);
        background: rgba(var(--ion-color-light-contrast-rgb), 0.3);

        ion-tab-bar {
            --ion-tab-bar-background: transparent;
            --border: none;
            flex-direction: column-reverse;
            justify-content: flex-end;
            contain: layout; // работает только для хрома
        }

        @include media-query-screen($min: 320px, $max: 1100px) {
            width: 57px;
            padding-left: 0;
        }
    }

    ion-tab-button {
    }

    &__item {
        height: var(--nc-tab-btn-height, 57px);
        width: 57px;
        margin-bottom: 1px;
        border-radius: 4px 0 0 4px;
        background: var(--ion-background-color, #ffffff);

        &:not(.tab-selected) {
            background: var(--ion-color-step-200, #cccccc);

            &:hover {
                background: var(--ion-background-color, #ffffff);
            }
        }
    }
}
