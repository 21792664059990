.nc-map-popup {
    display: none;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    width: 486px;
    height: 350px;
    max-height: 542px;
    border-radius: 8px 0 0 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    background: var(--ion-background-color, #ffffff);

    &--active {
        display: block;
    }
}
