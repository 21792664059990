.nc-dummy {
    --nc-corner-left: 100px;
    --nc-corner-bottom: 80px;
    --nc-corner-top: 80px;
    --nc-list-h-item-margin: 0 30px 0 0;
    --nc-dummy-bg-img: url(#{$path}/img/art.svg);
    --nc-dummy-bg-pos: inherit;
    --nc-dummy-bg-size: contain;

    .nc-entrance-theme_center & {
        --nc-corner-left: 50px;
        --nc-corner-right: 50px;
        --nc-corner-bottom: 90px;
        --nc-corner-top: 70px;
        --nc-list-h-item-margin: 0 0 0 20px;
        --nc-dummy-bg-img: url(#{$path}/img/art.svg), url(#{$path}/img/bg_dummy.svg);
        --nc-dummy-bg-pos: 0 0, calc(100% - var(--nc-corner-right)) center;
        --nc-dummy-bg-size: cover, calc(50% - var(--nc-corner-left) - var(--nc-corner-right)) auto;
    }
    // Для стэйдж полигона водяной знак
    .nc-entrance-theme_center--stage & {
        --nc-dummy-bg-img: url(#{$path}/img/art1.svg);
    }

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    width: 100%;
    padding: var(--nc-dummy-padding, 80px 100px);

    background-color: var(--nc-dummy-bg, #2f80ed);
    background-image: var(--nc-dummy-bg-img);
    // linear-gradient(132.73deg, #2F80ED 3.64%, #1566D4 90.3%, #23007A 105.17%);
    background-repeat: no-repeat;
    background-size: var(--nc-dummy-bg-size);
    background-position: var(--nc-dummy-bg-pos);

    font-size: 14px;
    line-height: 1.2;
    color: #fff;

    &__logo {
        max-height: 102px;
        .nc-entrance-theme_center & {
            max-width: 500px;
        }

        img {
            height: 100%;
            max-height: 100%;
            width: auto;
        }
    }

    a {
        color: #fff;
    }

    // types
    &_flex-start {
        justify-content: flex-start;
    }

    @include media-query-device($device: phone_new) {
        .nc-entrance-theme_center & {
            .nc-corner {
                position: static;
            }
        }
    }

    @include media-query-screen($min: 320px, $max: 1024px) {
        .nc-entrance-theme:not(.nc-entrance-theme_center) {
            --nc-corner-left: 50px;
            --nc-corner-bottom: 50px;
            --nc-corner-top: 50px;
            --nc-dummy-bg-size: cover;
        }

        padding: var(--nc-dummy-padding, 50px);
        line-height: 1.5;

        .nc-corner_left-top,
        .nc-corner_left-bottom {
            left: var(--nc-dummy-padding, 50px);
        }

        .nc-entrance-theme_center & {
            // --nc-corner-bottom: 90px;
            // --nc-corner-top: 70px;
            // --nc-dummy-bg-size: cover, calc(50% - var(--nc-corner-left) - var(--nc-corner-right) ) auto;
        }
    }

    @include media-query-device($device: iPhone_old, $orientation: portrait) {
        .nc-entrance-theme_center & {
            padding: 0 15px;
        }
    }
}

// Для стэйдж полигона повторяем водяной знак
.nc-entrance-theme_center--stage {
    .nc-dummy {
        background-repeat: repeat;
        background-size: unset;
        background-position: unset;
    }
}
