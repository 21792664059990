.nc-corner {
    position: absolute;

    &_left-top {
        left: var(--nc-corner-left, 0);
        top: var(--nc-corner-top, 0);
    }

    &_left-bottom {
        left: var(--nc-corner-left, 0);
        bottom: var(--nc-corner-bottom, 0);
    }

    &_right-top {
        right: var(--nc-corner-right, 0);
        top: var(--nc-corner-top, 0);
    }

    &_right-bottom {
        right: var(--nc-corner-right, 0);
        bottom: var(--nc-corner-bottom, 0);
    }
}
