.nc-chart {
    &-total {
        font-size: 18px;
        font-weight: 700;
    }

    // types
    &_bar-h {
        width: 100%;

        .nc-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: var(--ion-padding, 8px 5px);
            border-bottom: 2px dotted var(--ion-border-color, #d9d9d9);
            margin-bottom: 2px;

            &:hover {
                background: rgba(var(--ion-color-primary-rgb), 0.05);
            }

            &__gist {
                width: 50%;
                flex-shrink: 0;
            }

            &__value {
                font-weight: 700;
                font-size: 16px;

                &--disabled,
                &--muted,
                &[disabled] {
                    font-weight: 300;
                    color: var(--ion-color-step-200, #cccccc);

                    + .nc-item__legend {
                        // перенести в замьюченый класс gist
                        color: var(--ion-color-step-200, #cccccc) !important;
                    }
                }
            }

            &__bar {
                height: 35px;
                background: grey;
            }

            &__legend {
                @include text-ellipsis();
                font-size: 12px;
            }
        }
    }

    &_rtl {
        direction: rtl;
    }

    &_circle {
        circle {
            stroke: var(--ion-color-primary, #1772c6);
            stroke-width: 3px;
            stroke-linecap: butt;
            stroke-linejoin: round;
        }
        &__ring {
            opacity: 0.2;
        }
        &__segment {
            transform: rotate(-90deg);
            transform-origin: center center;
        }
    }

    // states
    &--tiny {
        &.nc-chart_bar-h {
            direction: rtl;

            .nc-item {
                padding: 0;
                border: 0;

                &__gist {
                    display: none;
                }
                &__bar {
                    height: 5px;
                }
            }
        }
    }
}
