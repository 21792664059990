@use 'sass:math';

$color-white: #ffffff !default;
$color-black: #000000 !default;
$grey-87-45: #202020 !default;
$grey-14-12: #dbdbdb !default;
$grey-default: #6a728c !default;
// $cluster-value-border:          $color-white !default;
$cluster-legend-bg: transparentize($grey-87-45, 0.2) !default;
$cluster-legend-color: $grey-14-12 !default;
$cluster-mix-value-bg: transparentize($color-white, 0.5) !default;
$cluster-pin-value-icon-bg: $color-white !default;
$pin-dark: #526068 !default;
$cluster-pin-value-before: $color-white !default;
$cluster-pin-value-bg: transparent !default;
$cluster-pin-legend-bg: $color-white !default;
$cluster-pin-legend-title: $grey-default !default;
$cluster-pin-legend-color: $color-black !default;
$contact-name: $grey-87-45 !default;
// $cluster-color-map:             (
//                                     platform: #28BD8B,
//                                     contragent:  #338AFF,
//                                     emitter: #2B75D9,
//                                     organization: #2360B2,
//                                     carrier: #9B51E0,
//                                     container: #1BA75E,
//                                 ) !default;
$status-color-map: (
    // contracts and all
    // raw - верный статус, draft- необходимо убрать из словарей
    raw: #a3afb7,
    // Черновик
    // draft:          #A3AFB7, // Черновик
    requested: #2295d1,
    // Заявка
    proposed: #2295d1,
    // Запрос данных
    oncheck: #7476b0,
    // Проверка инспектором
    oncheck_ps: #7476b0,
    // Проверка службой
    ready: #1d1f26,
    // Сформирован
    printed: #3760b5,
    // Распечатан
    sent: #4660e5,
    // Отправлен
    // sended:         #4660E5, // Отправлен
    delivered: #7b46e5,
    // Доставлен
    signed: #219452,
    // Подписан
    refused: #b84444,
    // Отклонен
    concluded: #1b7a44,
    // Заключен
    negotiate: #f8a629,
    // Урегулирование          #D98942
    changed_inbox: #d96a44,
    // Изменен контрагентом
    changed: #fecc36,
    // Изменен                 #D9B444
    cancelled: #a3a3a3,
    // Расторгнут
    fiat: #d96a44,

    // Понуждение с привлечением гос.органов
    // accrual
    paid: #118476,
    // Оплачен
    partially_paid: #b18b17,
    // Частично оплачен
    error_export: #eb5757,
    // Ошибка экспорта
    rejected: #b84444,
    // Отклонен
    accepted: #219452,

    // Акцептован
    // bid
    new: #3955f1,
    // Новая
    wait: #27b2a3,
    // Ожидает оплаты, ожидание ч-л
    pending: #27b2a3,
    // Ожидает оплаты, ожидание ч-л
    underway: #186ce0,
    // В работе
    success: #219452,

    // Выполнена
    // platform
    tocheck: #70e3d7,
    // Заявка на проверку
    fact: #76838e,
    // Фактическая
    checked: #6ea425,
    // Проверенная
    active: #186ce0,
    // Действующая
    closed: #a3a3a3,
    // Закрыта
    planed: #4390f4,
    // Плановая
    unknown: #a3afb7,
    // Неизвестная
    inspection: #2f80ed,

    // Инспекция
    // Действия договор
    formed: #d9b444,

    // Сформировать, Яр
    // Маршруты
    performed_driver: #27b2a3,
    // Выполняется водителем
    available_driver: #fecc36,
    // Доступен водителю
    completed_driver: #7b46e5,
    // Завершен водителем
    loaded_driver: #3760b5,
    // Загружен водителем
    passed_driver: #186ce0,
    // Принят водителем
    checked_dispatcher: #1b7a44,
    // Проверен диспетчером
    breakdown_report: #b84444,
    // Сообщение о поломке // переименовать статус в словаре
    update_required: #f8a629,
    // Требует обновления
) !default;
$state-container-color-map: (
    container_idle: #a3afb7,
    container_wait: #526068,
    container_plan: #22b2d2,
    container_delay: #9368f4,
    container_rfid: #5b8f19,
    container_mapp: #95ca45,
    container_checkin: #fedb44,
    container_warn: #f8a629,
    container_fail: #dc4e1a,
    container_late: #987b71,
) !default;

.nc-cluster {
    position: absolute;
    height: 62px;

    &__value {
        position: absolute;
        top: 0;
        left: 0;

        @include flex-column(column, center);
        border-radius: 50%;

        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        line-height: 18px;
        font-weight: 700;
        text-align: center;
        color: var(--ion-color-primary, #1772c6);

        .nc-value {
            position: relative;
            z-index: 2;
            color: var(--nc-gmap-label-color);
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            border-radius: 50%;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 50%;
            background: var(--nc-gmap-cluster-color, #ffffff);

            transform-origin: center center;
            transition: transform 0.2s linear 0.2s;
        }
    }

    &__ring {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        transform: scale(1);
        transform-origin: center center;
        transition: transform 0.2s linear 0.2s;

        pointer-events: none;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        transform: rotate(90deg);
        transform-origin: center center;

        circle {
            position: absolute;
            stroke-width: 4px; // 10px;
            stroke-linecap: butt;
            stroke-linejoin: round;
            fill: none;

            stroke: var(--nc-gmap-ring-color, #1772c6);
        }

        // @each $key, $value in $cluster-color-map {
        //     &.nc-sector_#{$key} circle {
        //         stroke: $value;
        //     }
        // }
        // @each $key, $value in $status-color-map {
        //     &.nc-sector_#{$key} circle {
        //         stroke: $value;
        //     }
        // }
        // @each $key, $value in $state-container-color-map {
        //     &.nc-sector_#{$key} circle {
        //         stroke: $value;
        //     }
        // }

        // @for $i from 1 through 99 {
        //     &[percent="#{$i}"] {

        //         + svg {
        //             transform: rotate(#{ $i*360/100 + 90 }deg);

        //             @for $j from 1 through 99 {
        //                 &[percent="#{$j}"] {

        //                     + svg {
        //                         transform: rotate(#{ ($i+$j)*360/100 + 90 }deg);
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    &__legend {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: -1;
        margin: auto;

        height: 29px;
        padding: 0 10px 0 20px;
        border-radius: 0 3px 3px 3px;
        background: $cluster-legend-bg;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 12px;
        line-height: 30px;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;
        color: $cluster-legend-color;

        opacity: 0;
        transition: opacity 0.2s linear 0s;

        pointer-events: none;

        .nc-item {
            position: relative;
            display: flex;
            align-items: center;
            line-height: 30px;

            &::before {
                background: var(--ion-color-primary-contrast, #ffffff);
            }

            &__value {
                display: none;
            }

            // @each $key, $value in $cluster-color-map {
            //     &.nc-item_#{$key}::before {
            //         background: $value;
            //     }
            // }
        }
    }

    // @each $key, $value in $cluster-color-map {
    //     &_#{$key} {
    //         .nc-cluster__value::before {
    //             background: mix($color-white, $value, 90%);
    //         }

    //         &:hover {
    //             .nc-cluster__value::before {
    //                 background: $color-white;
    //             }
    //         }
    //     }
    // }

    &--w1,
    &--w10 {
        // --nc-cluster-radius: 21;
        // --nc-cluster-circumference: calc( 2 * Math.Pi * var(--nc-cluster-radius));

        .nc-cluster__value {
            width: 42px;
            height: 42px;
            margin: 10px;

            font-size: 12px;
            line-height: 14px;

            .icon,
            .asuicon,
            .enticon {
                font-size: 20px;
            }

            &::before {
                width: 34px;
                height: 34px;
                margin: -17px 0 0 -17px;
            }

            &:hover {
                &::after {
                    transform: scale(1.4285);
                }

                ~ .nc-cluster__ring {
                    transform: scale(1.1904);
                }
            }
        }

        svg,
        .nc-cluster__ring {
            width: 62px; // 78px
            height: 62px;

            circle {
                // stroke-dasharray: var(--nc-cluster-circumference);
                stroke-dasharray: 131.94689145;
            }

            @for $i from 1 through 99 {
                &[percent='#{$i}'] {
                    circle {
                        // stroke-dashoffset: #{var(--nc-cluster-circumference) - calc(var(--nc-cluster-circumference) * ($i/100)) + 1};
                        stroke-dashoffset: #{131.94689145 - (131.94689145 * math.div($i, 100)) + 1};
                    }
                }
            }
        }

        .nc-cluster__legend {
            left: 50px; // 63px
        }
    }
    &--w100 {
        height: 86px;

        .nc-cluster__value {
            width: 68px;
            height: 68px;
            margin: 9px;

            &::before {
                width: 60px;
                height: 60px;
                margin: -30px 0 0 -30px;
            }

            &:hover {
                // width: 86px;
                // height: 86px;
                // margin: 0;

                &::after {
                    transform: scale(1.2647);
                }

                ~ .nc-cluster__ring {
                    // transform: scale(1.09375);
                    transform: scale(1.1724);
                }
            }
        }

        svg,
        .nc-cluster__ring {
            width: 86px;
            height: 86px;

            circle {
                stroke-dasharray: 182.21237;
            }

            @for $i from 1 through 99 {
                &[percent='#{$i}'] {
                    circle {
                        stroke-dashoffset: #{182.21237 - (182.21237 * math.div($i, 100)) + 1};
                    }
                }
            }
        }

        .nc-cluster__legend {
            left: 71px;
        }
    }
    &--w1000 {
        height: 94px;

        .nc-cluster__value {
            width: 78px;
            height: 78px;
            margin: 8px;

            &::before {
                width: 68px;
                height: 68px;
                margin: -34px 0 0 -34px;
            }

            &:hover {
                // width: 94px;
                // height: 94px;
                // margin: 0;

                &::after {
                    transform: scale(1.2051);
                }

                ~ .nc-cluster__ring {
                    // transform: scale(1.06849);
                    transform: scale(1.1176);
                }
            }
        }

        svg,
        .nc-cluster__ring {
            width: 94px;
            height: 94px;

            circle {
                stroke-dasharray: 213.6283;
            }

            @for $i from 1 through 99 {
                &[percent='#{$i}'] {
                    circle {
                        stroke-dashoffset: #{213.6283 - (213.6283 * math.div($i, 100)) + 1};
                    }
                }
            }
        }

        .nc-cluster__legend {
            left: 79px;
        }
    }
    &--w10000 {
        height: 102px;

        .nc-cluster__value {
            width: 88px;
            height: 88px;
            margin: 7px;

            &::before {
                width: 76px;
                height: 76px;
                margin: -38px 0 0 -38px;
            }

            &:hover {
                // width: 102px;
                // height: 102px;
                // margin: 0;

                &::after {
                    transform: scale(1.15909);
                }

                ~ .nc-cluster__ring {
                    // transform: scale(1.04878);
                    transform: scale(1.0898);
                }
            }
        }

        svg,
        .nc-cluster__ring {
            width: 102px;
            height: 102px;

            circle {
                stroke-dasharray: 245.04423;
            }

            @for $i from 1 through 99 {
                &[percent='#{$i}'] {
                    circle {
                        stroke-dashoffset: #{245.04423 - (245.04423 * math.div($i, 100)) + 1};
                    }
                }
            }
        }

        .nc-cluster__legend {
            left: 87px;
        }
    }
    &--w100000 {
        height: 114px;

        .nc-cluster__value {
            width: 98px;
            height: 98px;
            margin: 8px;

            font-size: 16px;
            line-height: 19px;

            &::before {
                width: 86px;
                height: 86px;
                margin: -43px 0 0 -43px;
            }

            &:hover {
                // width: 114px;
                // height: 114px;
                // margin: 0;

                &::after {
                    transform: scale(1.16326);
                }

                ~ .nc-cluster__ring {
                    // transform: scale(1.04347);
                    transform: scale(1.0966);
                }
            }
        }

        svg,
        .nc-cluster__ring {
            width: 114px;
            height: 114px;

            circle {
                stroke-dasharray: 276.46015;
            }

            @for $i from 1 through 99 {
                &[percent='#{$i}'] {
                    circle {
                        stroke-dashoffset: #{276.46015 - (276.46015 * math.div($i, 100)) + 1};
                    }
                }
            }
        }

        .nc-cluster__legend {
            left: 99px;
        }
    }
    &--w1000000 {
        height: 122px;

        .nc-cluster__value {
            width: 108px;
            height: 108px;
            margin: 7px;

            font-size: 18px;
            line-height: 21px;

            &::before {
                width: 94px;
                height: 94px;
                margin: -47px 0 0 -47px;
            }

            &:hover {
                // width: 122px;
                // height: 122px;
                // margin: 0;

                &::after {
                    transform: scale(1.1296);
                }

                ~ .nc-cluster__ring {
                    // transform: scale(1.0297);
                    transform: scale(1.0663);
                }
            }
        }

        svg,
        .nc-cluster__ring {
            width: 122px;
            height: 122px;

            circle {
                stroke-dasharray: 307.87608;
            }

            @for $i from 1 through 99 {
                &[percent='#{$i}'] {
                    circle {
                        stroke-dashoffset: #{307.87608 - (307.87608 * math.div($i, 100)) + 1};
                    }
                }
            }
        }

        .nc-cluster__legend {
            left: 107px;
        }
    }

    &_mix {
        .nc-cluster__value {
            &::before {
                background: $cluster-mix-value-bg;
            }

            &:hover {
                ~ .nc-cluster__ring {
                    transition: transform 0.2s linear 0s;
                }
            }
        }

        .nc-cluster__legend {
            bottom: auto;
            height: auto;
            margin-top: 0;
            padding: 11px 13px 1px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                background: $cluster-legend-bg;
            }

            .nc-item {
                margin: 0 0 10px;
                line-height: 14px;

                &::before {
                    content: '';
                    flex-shrink: 0;
                    width: 10px;
                    height: 10px;
                    border-radius: 2px;
                    margin-right: 8px;
                }

                &__value {
                    display: block;
                    flex-shrink: 0;
                    text-align: right;
                }
                &__title {
                    overflow: hidden;
                    text-overflow: clip;
                    min-width: 105px;
                    max-width: 190px;

                    &::after {
                        content: '................................................................................';
                        padding-left: 3px;
                    }
                }
            }
        }

        .nc-cluster__ring {
            transition: transform 0.2s linear 0.2s;
        }

        svg {
            circle {
                transition: none;
            }
        }

        &.nc-cluster--w1,
        &.nc-cluster--w10 {
            .nc-cluster__legend {
                left: 75px;

                &::before {
                    left: -39px;
                    width: 39px;
                    height: 39px;
                }

                .nc-item__value {
                    width: 15px;
                }
            }
        }
        &.nc-cluster--w100 {
            .nc-cluster__legend {
                left: 84px;

                &::before {
                    left: -43px;
                    width: 43px;
                    height: 43px;
                }

                .nc-item__value {
                    width: 22px;
                }
            }
        }
        &.nc-cluster--w1000 {
            .nc-cluster__legend {
                left: 89px;

                &::before {
                    left: -47px;
                    width: 47px;
                    height: 47px;
                }

                .nc-item__value {
                    width: 28px;
                }
            }
        }
        &.nc-cluster--w10000 {
            .nc-cluster__legend {
                left: 96px;

                &::before {
                    left: -51px;
                    width: 51px;
                    height: 51px;
                }

                .nc-item__value {
                    width: 34px;
                }
            }
        }
        &.nc-cluster--w100000 {
            .nc-cluster__legend {
                left: 105px;

                &::before {
                    left: -57px;
                    width: 57px;
                    height: 57px;
                }

                .nc-item__value {
                    width: 39px;
                }
            }
        }
        &.nc-cluster--w1000000 {
            .nc-cluster__legend {
                left: 115px;

                &::before {
                    left: -61px;
                    width: 61px;
                    height: 61px;
                }

                .nc-item__value {
                    width: 46px;
                }
            }
        }
    }

    &__value:hover {
        &::after {
            transition: transform 0.2s linear 0s;
        }

        ~ .nc-cluster__ring {
            transition: transform 0.2s linear 0s;
        }

        ~ .nc-cluster__legend {
            opacity: 1;
            transition: opacity 0.2s linear 0.2s;
        }
    }

    &:hover {
        z-index: 10000 !important; // important употреблен из-за инлайновского z-index лифлета

        .nc-cluster__legend {
            will-change: opacity;
        }

        .nc-cluster__value::after {
            will-change: transform;
        }
    }

    &_pin {
        top: -2px;
        left: -2px;
        right: -2px;
        height: 41px;
        width: 41px;

        .nc-cluster__value {
            bottom: 0;
            right: 0;
            // opacity: 0;

            > .icon,
            > .asuicon,
            > .enticon {
                border-radius: 50%;
                background: $cluster-pin-value-icon-bg;
                line-height: 37px;
                font-size: 17px;
                text-align: center;
                color: $pin-dark;
            }

            .nc-pin_dark & {
                border: 2px solid $pin-dark;
            }

            &::before {
                background: $cluster-pin-value-before;
            }
            &::after {
                background: $cluster-pin-value-bg;
            }
        }

        svg,
        .nc-cluster__ring {
            width: 41px; //29
            height: 41px;

            circle {
                stroke-dasharray: 91.10619;
                stroke-width: 6px;
            }

            @for $i from 1 through 99 {
                &[percent='#{$i}'] {
                    circle {
                        stroke-dashoffset: #{91.10619 - (91.10619 * math.div($i, 100)) + 1};
                    }
                }
            }
        }

        .nc-cluster__legend {
            left: 33px;
            bottom: auto;
            height: auto;
            width: 258px;
            padding: 12px;
            border-radius: 0;
            background: $cluster-pin-legend-bg;
            line-height: 1;
            white-space: normal;
            text-transform: none;
            color: $cluster-pin-legend-color;

            &::before {
                content: '';
                position: absolute;
                left: -15px;
                top: 0;
                width: 20px;
                height: 20px;
                background: $cluster-pin-legend-bg;
            }

            .nc-address {
                margin: 0 0 12px;
                font-family: 'Roboto', sans-serif;
                font-size: 11px;
                font-weight: 400;
                line-height: 13px;
                color: $contact-name;
            }

            .nc-item {
                display: block;
                margin: 0 0 15px;

                .nc-txt {
                    position: relative;
                    margin: 0 0 4px;
                    padding-left: 21px;
                    font-size: 11px;
                    line-height: 13px;
                    font-weight: 300;

                    &_color {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 14px;
                        height: 14px;
                    }

                    &_bold {
                        font-weight: 700;
                    }

                    &_major {
                        line-height: 1.2;
                    }
                }

                // @each $key, $value in $state-container-color-map {
                //     &_#{$key} .nc-txt::before {
                //         background: $value;
                //     }
                // }
            }

            .nc-preloader {
                position: static;
                height: 3px;
            }
        }

        .nc-legend__title {
            margin: 0 0 4px;
            font-family: 'Roboto', sans-serif;
            line-height: 14px;
            text-transform: uppercase;
            color: $cluster-pin-legend-title;
        }

        .nc-legend__list {
            overflow: auto;
            max-height: 200px;
        }

        .nc-pin--active & .nc-cluster__ring {
            transform: scale(1.1);
        }

        .nc-back-area__map & {
            top: -36px;
            left: -14px;
            right: auto;
            bottom: auto;

            &__legend {
                display: none;
            }

            &:hover {
                .nc-cluster__legend {
                    display: block;
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }
}
