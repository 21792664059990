@mixin flex-column($direction: column, $justify: flex-start) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
}

@mixin flex-row($direction: row, $justify: space-between) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
}
