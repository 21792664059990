.nc-menu {
    &_level-2 {
        margin-bottom: 25px;

        ion-item {
            --inner-padding-end: 16px;
            font-size: 12px;

            ion-icon {
                opacity: 0;
                transition: opacity linear 0.2s;

                &[size='small'] {
                    margin-right: 22px;
                    margin-left: 6px;
                }
            }

            &:hover,
            &.nc-menu__item--active {
                ion-icon {
                    opacity: 1;
                }
            }
        }

        ion-label {
            margin: 0;
        }

        .item.sc-ion-label-md-h,
        .item .sc-ion-label-md-h {
            white-space: normal;
        }
    }
}
