.nci-card {
    --background: var(--ion-item-background, transparent);
    --color: var(--ion-color-step-550, #737373);
    --ion-safe-area-left: 0px;
    --ion-safe-area-right: 0px;

    display: block;
    position: relative;
    margin-bottom: 10px;
    border: var(--nci-card-border);
    border-radius: var(--nci-card-border-radius);
    background: var(--background);

    // box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),
    //             0 2px 2px 0 rgba(0,0,0,.14),
    //             0 1px 5px 0 rgba(0,0,0,.12);

    font-family: var(--ion-font-family, inherit);
    font-size: 14px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: var(--color);

    ion-item {
        &:first-child {
            --border-radius: var(--nci-card-border-radius) var(--nci-card-border-radius) 0 0;
        }
        &:last-child {
            --border-radius: 0 0 var(--nci-card-border-radius) var(--nci-card-border-radius);

            &:first-child {
                --border-radius: var(--nci-card-border-radius);
            }
        }
    }

    &::after {
        content: '';
        display: table;
        width: 100%;
    }

    .nc-modal__main & {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
    }
}
