$marker-cluster-small: rgba(181, 226, 140, 0.6) !default;
$marker-cluster-small-el: rgba(110, 204, 57, 0.6) !default;
$marker-cluster-medium: rgba(241, 211, 87, 0.6) !default;
$marker-cluster-medium-el: rgba(240, 194, 12, 0.6) !default;
$marker-cluster-large: rgba(253, 156, 115, 0.6) !default;
$marker-cluster-large-el: rgba(241, 128, 23, 0.6) !default;
$leaflet-oldie-marker-cluster-small: rgb(181, 226, 140) !default;
$leaflet-oldie-marker-cluster-small-el: rgb(110, 204, 57) !default;
$leaflet-oldie-marker-cluster-medium: rgb(241, 211, 87) !default;
$leaflet-oldie-marker-cluster-medium-el: rgb(240, 194, 12) !default;
$leaflet-oldie-marker-cluster-large: rgb(253, 156, 115) !default;
$leaflet-oldie-marker-cluster-large-el: rgb(241, 128, 23) !default;

.marker-cluster-small {
    background-color: $marker-cluster-small;
}
.marker-cluster-small div {
    background-color: $marker-cluster-small-el;
}

.marker-cluster-medium {
    background-color: $marker-cluster-medium;
}
.marker-cluster-medium div {
    background-color: $marker-cluster-medium-el;
}

.marker-cluster-large {
    background-color: $marker-cluster-large;
}
.marker-cluster-large div {
    background-color: $marker-cluster-large-el;
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
    background-color: $leaflet-oldie-marker-cluster-small;
}
.leaflet-oldie .marker-cluster-small div {
    background-color: $leaflet-oldie-marker-cluster-small-el;
}

.leaflet-oldie .marker-cluster-medium {
    background-color: $leaflet-oldie-marker-cluster-medium;
}
.leaflet-oldie .marker-cluster-medium div {
    background-color: $leaflet-oldie-marker-cluster-medium-el;
}

.leaflet-oldie .marker-cluster-large {
    background-color: $leaflet-oldie-marker-cluster-large;
}
.leaflet-oldie .marker-cluster-large div {
    background-color: $leaflet-oldie-marker-cluster-large-el;
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}
.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
}
.marker-cluster span {
    line-height: 30px;
}
