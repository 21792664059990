.nc-table {
    display: table;
    // table-layout: fixed;
    width: 100%;
    text-align: left;

    &-wrapper {
        margin: 10px 0;
        overflow: auto;
    }

    &__row {
        display: table-row;
        padding: 0;

        &_sticky {
            position: relative;

            [class*='nc-cell'] {
                position: sticky;
                top: 0;
                z-index: 1;
                background: var(--ion-background-color);

                .nc-table_native & {
                    border-bottom-width: 3px;
                }
            }
        }
    }

    &__cell {
        position: relative;
        display: table-cell;
        min-height: 60px;
        padding: 5px;
        border-bottom: 1px solid
            var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
        vertical-align: middle;

        &_caption {
            display: table-cell;
            min-height: 40px;
            padding: 10px 5px;
            background: var(--ion-color-step-100);

            border-bottom: 3px solid
                var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
            vertical-align: bottom;

            font-weight: 700;
            font-size: 10px;
            text-transform: uppercase;
        }

        ion-select {
            width: 100%;
            max-width: 90%;
        }

        .nc-table__row:hover & {
            background: var(--ion-color-step-50);
        }

        .nc-table__foot & {
            background: var(--ion-color-step-100);
            font-weight: 700;
            // font-size: 10px;
            // text-transform: uppercase;
        }
    }

    // types
    &_lines {
        .nc-table__cell:not(.nc-table__foot .nc-table__cell) {
            border: 1px solid
                var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
        }
    }

    &_native {
        --ion-background-color: var(--ion-color-light, #f4f5f8);
        background: var(--ion-background-color, #f4f5f8);

        td,
        th {
            padding: 10px 5px;
            border-bottom: 1px solid
                var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
        }
    }
}
