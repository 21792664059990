.nc-entrance-page {
    display: flex;
    min-height: 100vh;
    font-size: 12px;

    .nc-dummy {
        position: relative;
        height: auto;

        .nc-entrance-info {
            // display: none;
            border: 1px solid white;
            width: 90%;
            padding: 8px;
            margin-bottom: 10px;
            font-size: 18px;
            // &.nc-entrance-info-important {
            //     background-color: white;
            //     color: red;
            //     border: 2px solid red;
            // }
        }

        .nc-entrance-theme_center & {
            --nc-dummy-padding: 140px 50px;
            align-items: flex-start;

            h1 {
                margin: 0;
            }

            p {
                // margin: 60px 0;
                font-size: 14px;
            }
        }
    }

    h2 {
        margin: 0 0 0.5em;
        font-weight: 700;
        font-size: 24px;
        text-transform: uppercase;
    }

    h3 {
        font-weight: 700;
        font-size: 14px;
    }

    :not(.nc-entrance-theme_center) & {
        p {
            & + .nc-list {
                margin-top: 47px;
            }
        }
    }

    .nc-list {
        &_horizontal {
            .nc-entrance-theme_center & {
                margin-top: 7px;
                font-size: 18px;
                text-align: right;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;

        width: 510px;
        // height: 100%;
        padding: 50px;

        background: #fff;
        box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);

        font-size: 14px;
        line-height: 19px;

        --ion-text-color: #000000;
        --ion-text-color-rgb: 0, 0, 0;

        &__col {
            max-width: 400px;
        }

        ion-list-header {
            padding-left: 0;
            font-size: 24px;
            font-weight: 500;
        }

        a {
            color: var(--ion-color-primary, #1566d4);
        }

        form {
            min-height: 355px;
        }

        .nc-input-group {
            margin: 24px 0;

            &__label {
                pointer-events: all;
                transform: none;
                font-size: 14px;

                ~ .nc-input-group__field {
                    margin-top: 0;
                    ion-input {
                        min-height: 40px;
                        --padding-start: 10px;
                    }
                }
            }

            &__field {
                --border-radius: 3px;
                background: #fafafa;
                border: 1px solid #ecedef;
                padding-top: 0;
                padding-bottom: 0;
                color: var(--ion-text-color);
            }
        }

        .nc-entrance-theme_center & {
            flex-direction: row;
            justify-content: space-around;

            width: 50%;
            max-width: 670px;
            min-height: 470px;
            // height: auto;
            height: 470px;
            margin: auto 0;
            padding: 24px 10px;

            a {
                border-bottom: 1px solid var(--ion-color-primary, #3d73d7);
                text-decoration: none;
            }

            form {
                min-height: 293px;
            }

            .nc-input-group {
                &__label {
                    position: static;
                }
            }
            ion-button {
                --border-radius: 4px;
                width: 280px;
                margin: 0 auto 25px;
                text-transform: none;
                letter-spacing: normal;
            }
        }
    }

    // &__test-mode {
    //     font-size: 22px;
    //     line-height: 1;
    // }

    .nc-logo {
        img {
            height: 32px;
            width: auto;
        }

        &__txt {
            font-size: 12px;
            line-height: 115%;
            color: #434f61;

            &_accent {
                font-size: 30px;
                font-weight: 700;
                color: #2f80ed;
            }
        }

        .nc-corner {
            &_right-bottom {
                --nc-list-h-item-margin: 0 0 0 20px;
                text-align: right;
            }
        }

        & + div ion-button {
            width: 201px;
            margin: 26px 0;
            font-weight: 400;
            font-size: 12px;
            text-transform: none;
            letter-spacing: normal;
        }

        & ~ .nc-list {
            margin-top: 10px;
        }
    }

    @include media-query-screen-h($min: 300px, $max: 725px, $orientation: landscape) {
        .nc-entrance-theme:not(.nc-entrance-theme_center) & {
            min-height: 724px;

            .nc-dummy {
                --nc-corner-left: 50px;
                --nc-corner-bottom: 50px;
                --nc-corner-top: 50px;
                --nc-dummy-padding: 50px;
            }
        }
    }

    @include media-query-screen-h($min: 300px, $max: 846px, $orientation: landscape) {
        .nc-entrance-theme_center & {
            // min-height: 768px;

            .nc-dummy {
                --nc-corner-top: 30px;
                --nc-corner-bottom: 50px;
            }
        }
    }

    @include media-query-device($device: smartphone) {
        .nc-entrance-theme:not(.nc-entrance-theme_center) & {
            flex-direction: column;

            p,
            h3 {
                display: none;
            }

            .nc-entrance-info {
                width: 100%;
            }

            .nc-dummy {
                --nc-corner-left: 20px;
                --nc-corner-bottom: 20px;

                justify-content: space-between;

                min-height: 35%;
                padding: 20px;
            }

            &__content {
                min-height: 60%;
                height: auto;

                .nc-list {
                    .nc-item {
                        width: 100%;
                        margin: 10px 0;
                    }
                }
            }
        }

        &__content {
            width: 100%;
            padding: 20px;
        }

        // .nc-entrance-theme_center & {
        //     // height: auto;

        //     &__content {
        //         // /margin: 24px 0;
        //     }
        // }
    }

    @include media-query-device($device: smartphone, $orientation: portrait) {
        .nc-entrance-theme:not(.nc-entrance-theme_center) & {
            &__content {
                justify-content: flex-start;
            }
        }

        .nc-entrance-theme_center & {
            .nc-dummy {
                --nc-corner-bottom: 70px;
                --nc-dummy-bg-pos: 0 0, calc(100% - var(--nc-corner-right)) calc(100% - var(--nc-corner-bottom));
                --nc-dummy-bg-size: cover, 60% auto;
                --nc-list-h-item-margin: 0 20px 0 0;

                &__logo {
                    max-width: 50%;
                }
            }
            &__content {
                flex-direction: column;
                align-items: center;
                width: 100%;
                max-width: 100%;
                margin: 20px 0;
            }
            .nc-corner {
                &_right-bottom {
                    margin-top: 30px;
                }
            }
            .nc-list_horizontal {
                text-align: left;
            }
        }
    }

    @include media-query-device($device: iPhone_old, $orientation: portrait) {
        .nc-entrance-theme:not(.nc-entrance-theme_center) & {
            h2 {
                font-size: 20px;
            }
            .nc-dummy {
                --nc-corner-top: 65px;

                &__logo {
                    max-height: 60px;
                }

                .nc-corner {
                    position: static;
                }
            }
        }

        .nc-entrance-theme_center & {
            .nc-dummy p {
                margin: 12px 0;
            }
            .nc-corner {
                &_right-bottom {
                    margin-top: 10px;
                    font-size: 12px;
                }
            }
            .nc-list_horizontal {
                font-size: 14px;
            }
        }
    }

    @include media-query-device($device: phone_new, $orientation: portrait) {
        .nc-entrance-theme:not(.nc-entrance-theme_center) & {
            h2 {
                font-size: 24px;
            }

            .nc-dummy {
                --nc-corner-top: 0;
                // padding-bottom: 120px;
                flex: 1;

                &__logo {
                    // max-height: 80px;
                    text-align: left;
                }

                .nc-corner {
                    position: static;
                }
            }
            &__content {
                flex: 1;
            }
        }

        .nc-entrance-theme_center & {
            &__content {
                flex-direction: column;
                align-items: center;
            }
            .nc-dummy {
                h1 {
                    font-size: 36px;
                }
                p {
                    text-align: left;
                }
            }
        }
    }

    @include media-query-screen($min: 768px, $max: 1024px, $orientation: portrait) {
        .nc-entrance-theme:not(.nc-entrance-theme_center) & {
            flex-direction: column;

            .nc-dummy {
                justify-content: space-between;
                flex: 1;
                min-height: 600px;

                .nc-corner {
                    position: static !important;
                }
                // .nc-corner.nc-corner_left-top {top: 0px}

                &__logo {
                    text-align: right;
                }
            }
            &__content {
                flex: 1;
                height: 50%;
                justify-content: flex-start;
                width: 100%;

                .nc-list {
                    display: flex;
                    flex-wrap: wrap;

                    .nc-item {
                        width: 50%;
                    }
                }
            }
        }

        .nc-entrance-theme_center & {
            .nc-dummy {
                --nc-corner-bottom: 70px;
                --nc-dummy-bg-pos: 0 0, calc(100% - var(--nc-corner-right)) calc(100% - var(--nc-corner-bottom));
                --nc-dummy-bg-size: cover, 60% auto;
                --nc-list-h-item-margin: 0 20px 0 0;
            }
            &__content {
                flex-direction: column;
                align-items: center;
                width: 100%;
                max-width: 100%;
            }
            .nc-corner {
                position: static;

                &_right-bottom {
                    margin-top: 30px;
                }
            }
            .nc-list_horizontal {
                text-align: left;
            }
        }
    }

    @include media-query-device($device: iPad, $orientation: portrait) {
        .nc-entrance-theme:not(.nc-entrance-theme_center) & {
            .nc-dummy {
                padding-top: 140px;

                .nc-corner {
                    position: absolute;
                }
            }
        }
    }

    /// landscape
    @include media-query-screen($min: 813px, $max: 1200px, $orientation: landscape) {
        .nc-entrance-theme:not(.nc-entrance-theme_center) & {
            .nc-dummy {
                .nc-corner {
                    position: static; //Убрать при скрытии анонса
                }
            }
            // &__test-mode {
            //     position: absolute;
            //     left: 0;
            //     top: calc( var(--nc-dummy-padding, 50px) / -2);
            //     white-space: nowrap;
            // }
        }
        .nc-entrance-theme_center & {
            .nc-dummy {
                --nc-corner-top: 30px;
                --nc-corner-bottom: 50px;
            }
        }
    }

    @include media-query-device($device: smartphone, $orientation: landscape) {
        .nc-entrance-theme:not(.nc-entrance-theme_center) & {
            .nc-dummy {
                --nc-corner-top: 30px;
                --nc-list-h-item-margin: 0 20px 0 0;
                min-height: 80%;
                height: auto;

                &__logo {
                    max-height: 60px;
                }
            }

            &__content {
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;

                .nc-logo {
                    &__txt {
                        margin-top: 10px;
                    }

                    & + ion-button {
                        margin: 0;
                    }
                }

                .nc-list {
                    margin-top: 10px;
                }

                .nc-form {
                    width: 100%;
                }
            }
        }
        .nc-corner {
            position: static;
        }
        .nc-entrance-theme_center & {
            .nc-dummy {
                --nc-list-h-item-margin: 0 20px 0 0;
                p {
                    margin: 12px 0;
                }
            }
            &__content {
                margin: 20px 0;
            }
            .nc-corner {
                &_right-bottom {
                    margin-top: 10px;
                }
            }
            .nc-list_horizontal {
                text-align: left;
            }
        }
    }
}
