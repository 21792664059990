.nc-datepicker {
    --nc-datepicker-width: 340px;
    --nc-calendar-primary: var(--ion-color-primary, #1772c6);
    --nc-calendar-head-bg: var(--ion-color-primary, #1772c6);
    --nc-calendar-foot-btn-color: var(--ion-color-primary, #1772c6);
    --nc-datepicker-selected-bg: var(--ion-color-primary, #1772c6);
    --nc-scoreboard-primary: var(--ion-color-primary, #1772c6);
    --nc-calendar-current-color: var(--ion-color-primary, #1772c6);

    position: static;
    // left: 0;
    // top: 61px;
    // z-index: 10;

    .nc-calendar__body {
        .nc-calendar__btn {
            background: rgba(var(--ion-color-primary-rgb), 0.1);
            &:hover {
                background: var(--nc-calendar-btn-bg-hover, transparentize(#313541, 0.9));
            }
        }
    }
}

.nc-timepicker {
    --nc-scoreboard-primary: var(--ion-color-primary, #1772c6);
    --nc-calendar-foot-btn-color: var(--ion-color-primary, #1772c6);

    position: static;
    // left: 0;
    // top: 61px;
    // z-index: 100;

    .nc-datepicker & {
        position: static;
    }

    &__scoreboard {
        & + .nc-calendar__foot {
            --nc-calendar-foot-padding: 20px 17px;
        }
    }
}

.nc-modal_datepicker {
    --width: 340px;
    --height: 614px;
    --min-height: 143px;

    app-modal-date {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &::part(content) {
        --box-shadow: none;
        background: transparent;
    }
}
