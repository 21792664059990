.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
    transition:
        transform 0.3s ease-out,
        opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
    transition:
        stroke-dashoffset 0.3s ease-out,
        stroke-opacity 0.3s ease-in;
}
