.nc-modal {
    &__content {
        justify-content: space-between;
        height: 100%;
        // overflow: hidden;

        @include media-query-screen($min: 320px, $max: 960px) {
            padding: 0px;
        }
    }

    &__main {
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 16px;

        iframe {
            height: 100%;
            width: 100%;
            margin: 0;
            border: 0;
        }
    }

    &__aside {
        width: var(--side-max-width);
        overflow: auto;
        flex-shrink: 0;
        padding: 16px;

        & + .nc-modal__main {
            border-right: 1px solid var(--ion-color-step-100);
        }

        .nc-modal__main + & {
            border-left: 1px solid var(--ion-color-step-100);
        }

        @include media-query-screen($max: 960px) {
            width: 30%;
        }

        ion-item {
            &:first-child {
                border-bottom-width: 1px;
                border-bottom-style: solid;

                &:last-child {
                    border-bottom: none;
                }
            }

            ion-icon[slot='start'] {
                margin-right: 16px;
            }
        }
    }

    &_right,
    &_wide-child {
        left: var(--side-max-width);

        &::part(content) {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            --width: 100%;
        }

        @include media-query-screen($min: 320px, $max: 1100px) {
            left: 57px;
        }
    }

    &_right {
        &:first-of-type {
            --ion-backdrop-opacity: 0;
        }
        &::part(content) {
            height: 100%;
        }
    }

    &_wide-child {
        --ion-backdrop-opacity: 0;
        --background: transparent;

        &:last-child {
            --ion-backdrop-opacity: 0.32;
        }

        &::part(content) {
            --height: 100%;
        }

        ion-content {
            --background: white;

            &.nc-modal_wide-child__nomain {
                --background: transparent;

                .nc-modal__main {
                    visibility: hidden;
                }
                .nc-modal__aside {
                    background: white;
                }
            }
        }
    }

    &_iframe::part(content) {
        --width: 100%;
        --max-width: 1238px;
        --height: 100vh;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;

        .nc-modal__main {
            overflow: hidden;
        }

        iframe {
            height: 100%;
            width: 100%;
            margin: 0;
            border: 0;
        }

        @include media-query-screen($min: 320px, $max: 1100px) {
            --max-width: 100%;
        }
    }

    &_small {
        --width: 486px;
    }

    &_z-up {
        z-index: 100001 !important; // чтобы перебить програмный инлайновый стиль
    }
}
