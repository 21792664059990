.nc-aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    ion-item {
        --inner-padding-end: 0;

        ion-icon {
            margin-right: 20px;
            margin-left: 4px;
        }
        // ion-item {
        //     --padding-start: 0;

        //     ion-icon {
        //         margin-right: 15px;
        //         margin-left: 0;
        //     }
        // }
    }

    ion-item + ion-menu-toggle > ion-item {
        margin-top: 42px;
    }

    ion-menu-toggle ion-item {
        ion-buttons {
            margin-left: 16px;
        }
    }

    // ion-list {
    //     padding-top: 0;
    //     padding-bottom: 0;
    // }
}
