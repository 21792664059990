.nci-item {
    display: flex;
    align-items: center;
    flex: 1 1 0%;
    overflow: hidden;
    text-overflow: ellipsis;

    ion-avatar[slot],
    ion-thumbnail[slot] {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    ion-avatar[slot='start'] {
        width: 40px;
        height: 40px;
        margin-right: 16px;
    }

    .nci-list_visible > & {
        overflow: visible;
    }

    > ion-label {
        flex: 1 1 0%;
        margin: 11px 0 10px;
    }

    @include media-query-screen($min: 320px, $max: 960px, $orientation: portrait) {
        display: block;
    }

    + ion-buttons {
        .sc-ion-buttons-ios-s ion-icon[slot='start'] {
            -webkit-margin-end: 0;
            margin-inline-end: 0;
            margin-right: 0;
        }

        @include media-query-screen($min: 320px, $max: 960px) {
            flex-direction: column;

            ion-label.sc-ion-label-md-h,
            ion-label.sc-ion-label-ios-h {
                display: none;
            }
        }
    }

    // types

    &_lines {
        border-bottom: 1px solid
            var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
    }

    &_slotted {
        justify-content: space-between;

        ion-item {
            &:first-child,
            &:last-child {
                --border-radius: var(--nci-card-border-radius);
            }
        }

        > [slot='start'] {
            padding-left: var(--nci-item-padding-start, 16px);
        }
        > [slot='end'] {
            padding-right: var(--nci-item-padding-end, 16px);
        }
    }

    &_btn {
        --background-hover: var(--ion-item-color, var(--ion-text-color, #000));
        --background-hover-opacity: 0.04;
        position: relative;
        cursor: pointer;
        user-select: none;

        ion-item {
            --background: transparent;
        }

        &::after {
            content: '';
            inset: 0px;
            position: absolute;
            opacity: 0;
            transition: var(--transition);
            z-index: -1;
        }

        &:hover {
            &::after {
                background: var(--background-hover);
                opacity: var(--background-hover-opacity);
            }
        }
    }
}
