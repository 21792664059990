.nc-mask {
    position: absolute;
    width: 100%;
    opacity: 0.4;
    pointer-events: none;
    border: none;
    padding: 0;
    outline: none;

    .nc-input-group__field ~ & {
        padding: 8px 0 9px 2px;
    }
}
