// screen, print, all
// width, height, orientation, device-pixel-ratio, device-width
// media device & media screen
@mixin media-query-screen($min: false, $max: false, $orientation: false) {
    @if $min and $max {
        @if $orientation {
            @media only screen and (min-width: $min) and (max-width: $max) and (orientation: $orientation) {
                @content;
            }
        } @else {
            @media only screen and (min-width: $min) and (max-width: $max) {
                @content;
            }
        }
    } @else if $min or $max {
        $media-direction: if($min, min, max);
        $media-width: if($min, $min, $max);

        @if $orientation {
            @media only screen 
            and ( #{$media-direction}-width: $media-width )
            and ( orientation: $orientation ) {
                @content;
            }
        } @else {
            @media only screen 
            and ( #{$media-direction}-width: $media-width ) {
                @content;
            }
        }
    } @else {
        @media only screen and (orientation: $orientation) {
            @content;
        }
    }
}

@mixin media-query-screen-h($min: false, $max: false, $orientation: false) {
    @if $min and $max {
        @if $orientation {
            @media only screen and (min-height: $min) and (max-height: $max) and (orientation: $orientation) {
                @content;
            }
        } @else {
            @media only screen and (min-height: $min) and (max-height: $max) {
                @content;
            }
        }
    } @else if $min or $max {
        $media-direction: if($min, min, max);
        $media-height: if($min, $min, $max);

        @if $orientation {
            @media only screen 
            and ( #{$media-direction}-height: $media-height )
            and ( orientation: $orientation ) {
                @content;
            }
        } @else {
            @media only screen 
            and ( #{$media-direction}-height: $media-height ) {
                @content;
            }
        }
    } @else {
        @media only screen and (orientation: $orientation) {
            @content;
        }
    }
}

$devices: (
    // min-device-width, max-device-width, -webkit-min-device-pixel-ratio
    iPad:
        (
            min: 768px,
            max: 1024px,
        ),
    iPad_retina: (
        min: 768px,
        max: 1024px,
        ratio: 2,
    ),
    iPad_mini: (
        min: 768px,
        max: 1024px,
        ratio: 1,
    ),
    // min-device-width, max-device-width, -webkit-device-pixel-ratio
    iPhoneX:
        (
            min: 375px,
            max: 812px,
            ratio: 3,
        ),
    iPhone678: (
        min: 375px,
        max: 667px,
    ),
    iPhone678_plus: (
        min: 414px,
        max: 736px,
    ),
    iPhone5_5S: (
        min: 320px,
        max: 568px,
        ratio: 2,
    ),
    iPhone_old_retina: (
        min: 320px,
        max: 480px,
        ratio: 2,
    ),
    // 4, 4S
    iPhone_old:
        (
            min: 320px,
            max: 480px,
        ),
    // 2G, 3G, 4, 4S
    smartphone:
        (
            min: 320px,
            max: 851px,
        ),
    phone_new: (
        min: 375px,
        max: 823px,
    )
);

@function getDeviceProperty($key, $property) {
    @return map-get(map-get($devices, $key), $property);
}

@function hasDeviceRatio($key) {
    @return map-has-key(map-get($devices, $key), ratio);
}

@mixin media-query-device($device, $orientation: false, $ratio: false) {
    @if $orientation {
        @if $ratio or hasDeviceRatio($device) {
            $device_ratio: if($ratio, $ratio, getDeviceProperty($device, ratio));

            @media only screen and (min-device-width: getDeviceProperty($device, min)) and (max-device-width: getDeviceProperty($device, max)) and (orientation: $orientation) and (-webkit-min-device-pixel-ratio: $device_ratio) {
                @content;
            }
        } @else {
            @media only screen and (min-device-width: getDeviceProperty($device, min)) and (max-device-width: getDeviceProperty($device, max)) and (orientation: $orientation) {
                @content;
            }
        }
    } @else {
        @if $ratio or hasDeviceRatio($device) {
            $device_ratio: if($ratio, $ratio, getDeviceProperty($device, ratio));

            @media only screen and (min-device-width: getDeviceProperty($device, min)) and (max-device-width: getDeviceProperty($device, max)) and (-webkit-min-device-pixel-ratio: $device_ratio) {
                @content;
            }
        } @else {
            @media only screen and (min-device-width: getDeviceProperty($device, min)) and (max-device-width: getDeviceProperty($device, max)) {
                @content;
            }
        }
    }
}
