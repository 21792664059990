.nc-figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 46px;

    img {
        max-width: 100%;
        height: auto;
        max-height: 100%;
        width: auto;
    }

    ion-slides {
        height: 100%;
    }
}

.nc-figcaption {
    margin-top: 20px;
}
