$map-toolbox-bg: var(--ion-background-color, #ffffff) !default;
$map-toolbox-arrow-bg: #1d2028 !default;
$map-toolbox-arrow-color: #8c9aad !default;
$map-toolbox-control-bg: var(--ion-color-step-850, grey) !default;
$map-toolbox-control-border: var(--ion-color-secondary) !default;
$map-toolbox-icon-color: #c4c8cc !default;
$map-toolbox-border: #2a2e38 !default;
$map-toolbox-border-light: var(--ion-color-step-150, #dbdbdb) !default;
$map-toolbox-btn-bg: transparent !default;
$map-toolbox-btn-border: var(--ion-color-secondary) !default;
$map-toolbox-context-menu-item-color: var(--ion-text-color, #000000) !default;
$map-toolbox-context-menu-item-hover-color: var(--ion-color-step-50, #f7f7f7) !default;
$map-toolbox-context-menu-bg: var(--ion-color-step-50, #fafafa) !default;

.nc-map-toolbox {
    position: absolute;
    left: 13px;
    top: 0;
    z-index: 2; // top

    &--tiny {
        transform: translateY(-108px);
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        // @extend .nc-list-box-zero;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 108px;
        margin-left: 10px;

        background: $map-toolbox-bg;
        border-radius: 0 0 3px 3px;
        transition: transform 0.2s linear;
    }

    .nc-list__item {
        position: relative;
        height: 54px;
        width: 54px;
        background: $map-toolbox-control-bg;
        border-bottom: 1px solid $map-toolbox-border;

        .nc-btn {
            background-color: $map-toolbox-btn-bg;
            background-clip: padding-box;
            @include gradient-border($map-toolbox-control-border, $map-toolbox-control-bg);
            background-size:
                0 4px,
                100% 0px;
            background-position:
                center calc(100% - 1px),
                center calc(100% - 1px);
            transition: background 0.3s ease-out;

            &:hover {
                background-size:
                    100% 4px,
                    100% 4px;
            }
        }

        .icon,
        .asuicon {
            font-size: 20px;
            line-height: 54px;
            color: $map-toolbox-icon-color;
        }

        &:nth-child(even) {
            border-bottom: 0;
        }

        &:not(:nth-child(1), :nth-child(2)) {
            border-left: 1px solid $map-toolbox-border;
        }

        &_light {
            background: $map-toolbox-bg;
            border-color: $map-toolbox-icon-color;

            .nc-btn {
                background: transparent;
            }
        }

        &:last-child {
            position: absolute;
            top: 108px;

            z-index: 1;
            height: 22px;
            width: 162px;
            border-radius: 0 0 3px 3px;
            border: 0;
            background: $map-toolbox-arrow-bg;
            text-align: center;
            color: $map-toolbox-arrow-color;
            cursor: pointer;

            .icon {
                line-height: 22px;
                font-size: 19px;
            }
        }

        .nc-context-menu {
            right: auto;
            left: 27px;
            z-index: 2;
            width: 200px;
            padding-bottom: 8px;
            background: $map-toolbox-context-menu-bg;
            box-shadow: none;

            .icon,
            .asuicon {
                text-align: center;
                line-height: 28px;

                &.icon_papers {
                    padding-left: 4px;
                }
            }

            &__item {
                height: 28px;
                padding-left: 0;
                padding-right: 15px;
                line-height: 28px;
                // font-family: 'Roboto', sans-serif;
                font-size: 10px;
                font-weight: 500;
                text-transform: uppercase;
                color: $map-toolbox-context-menu-item-color;

                &:hover {
                    background: $map-toolbox-context-menu-item-hover-color;
                }

                &_caption {
                    height: 38px;
                    line-height: 38px;

                    &:hover {
                        background: transparent;
                    }
                }

                + .nc-context-menu__item_caption {
                    margin-top: 3px;
                }
            }

            .nc-checkbox {
                padding-left: 12px;

                &__label {
                    padding-left: 31px;
                }
            }
        }

        &--active {
            .nc-btn {
                background-size:
                    100% 4px,
                    100% 4px;
            }
        }
    }
}

// START leaflet styles
.leaflet-popup-tip-container {
    display: none;
}
.leaflet-pane {
    z-index: 0;
}

// .leaflet-touch {
.leaflet-control-zoom {
    margin-left: 131px;
    display: flex;
    flex-direction: column-reverse;
    // @include flex-column(column-reverse);
    width: 54px;

    a {
        display: block;
        overflow: hidden;
        width: 54px;
        height: 54px;
        margin: 0;
        border-bottom: 0;
        background: $map-toolbox-bg;
        text-align: center;
        text-decoration: none;
        font-size: 0;
        font-weight: 400;

        &::before {
            display: block;
            width: 54px;
            height: 54px;
            font-family: 'Linearicons';
            font-size: 20px;
            line-height: 54px;
            text-align: center;
            color: $map-toolbox-icon-color;
        }

        &.leaflet-control-zoom-in {
            &::before {
                content: '\e936';
            }
        }
        &.leaflet-control-zoom-out {
            border-bottom: 1px solid $map-toolbox-border-light;

            &::before {
                content: '\e937';
            }
        }
    }
}

.leaflet-control-layers-toggle {
    display: block;
    width: 54px;
    height: 54px;
    border-radius: 0;
    background: $map-toolbox-control-bg;
    box-shadow: none;
    text-decoration: none;

    &::before {
        content: '\e977';
        display: block;
        width: 53px;
        height: 54px;
        background-color: transparent;
        background-clip: padding-box;
        @include gradient-border(var(--ion-color-secondary), $map-toolbox-control-bg);
        background-size:
            0 4px,
            100% 0px;
        transition: background 0.3s ease-out;
        font-family: 'Linearicons';
        font-size: 20px;
        line-height: 54px;
        text-align: center;
        color: $map-toolbox-icon-color;
    }

    &:hover::before {
        background-size:
            100% 4px,
            100% 4px;
    }
}

.leaflet-control-layers {
    // position: absolute;
    // top: 54px;
    // left: 77px;
    margin: 0;
    border: 0;

    &-expanded {
        padding: 0;

        .leaflet-control-layers-toggle {
            display: block;

            &::before {
                background-size:
                    100% 4px,
                    100% 4px;
            }
        }
    }

    .nc-ws__main & {
        left: 131px;
    }
}

// }
.leaflet-touch.nc-map-wrapper_trip .leaflet-control-layers {
    left: 10px;
}

.leaflet-control-zoom,
.leaflet-control-layers,
.leaflet-pm-toolbar {
    transition: transform 0.2s linear; // top
}

.nc-map-toolbox--tiny ~ .leaflet-control-container .leaflet-control-zoom {
    transform: translateY(-108px);
}
.nc-map-toolbox--tiny ~ .leaflet-control-container .leaflet-control-layers {
    transform: translateY(-108px);
}

.leaflet-control-container {
    .leaflet-control-layers-list {
        position: absolute;
        top: 54px;
        left: 27px;
        bottom: auto;
        width: 220px;
        background: var(--ion-color-step-50, #fafafa);
    }

    .leaflet-control-layers label {
        height: 27px;
        line-height: 27px;
        // font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: 300;
        white-space: nowrap;
        color: var(--ion-color-step-850, #313541);
        cursor: pointer;

        &:hover {
            background: var(--ion-color-step-50, #f7f7f7);
        }

        .icon,
        .asuicon {
            display: inline-block;
            vertical-align: bottom;
            width: 27px;
            height: 27px;
            margin-right: 10px;
            background: var(--ion-color-step-850, #313541);
            border-bottom: 1px solid $map-toolbox-arrow-bg;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: $map-toolbox-icon-color;
        }

        &:last-child {
            .icon,
            .asuicon {
                border-bottom: 0;
            }
        }
    }

    input[type='radio'],
    input[type='checkbox'] {
        display: none;

        + span {
            display: block;
            padding-right: 10px;
            text-transform: none;
        }

        &:checked + {
            span {
                background: var(--ion-color-step-50, #f7f7f7);

                > .icon,
                > .asuicon {
                    color: var(--ion-color-secondary);
                }
            }
        }
    }
}

.leaflet-pm-toolbar.leaflet-bar {
    z-index: 0;
    left: 190px;
    height: 108px;
    width: 128px;
    flex-wrap: wrap;
    direction: rtl;

    a {
        width: 36px;
        height: 36px;
        line-height: 36px;
        border-bottom: 1px solid $map-toolbox-border-light;
        border-right: 1px solid $map-toolbox-border-light;
        font-size: 20px;
        text-align: center;
        color: $map-toolbox-icon-color;
        cursor: pointer;

        &.active {
            color: var(--ion-color-step-700, #4f4f4f);
        }

        &:nth-last-child(1),
        &:nth-last-child(2) {
            width: 54px;
            height: 54px;
            border: 0;
            background-color: $map-toolbox-control-bg;
            background-clip: padding-box;
            @include gradient-border(var(--ion-color-secondary), $map-toolbox-control-bg);
            background-size:
                0 4px,
                100% 0px;
            background-position:
                center 100%,
                center 100%;
            transition: background 0.3s ease-out;
            line-height: 54px;

            &.active {
                background-size:
                    100% 4px,
                    100% 4px;
                color: $map-toolbox-icon-color;
            }
        }

        &:nth-last-child(1) {
            border-bottom: 1px solid $map-toolbox-border;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            border-right: 0;
        }

        &:nth-child(1) {
            border-radius: 0 0 3px 0;
        }

        .control-icon {
            background: transparent;

            &.leaflet-pm-icon-marker::before {
                content: '\e77a';
                font-family: 'Linearicons';
            }
            &.leaflet-pm-icon-polyline::before {
                content: '\36';
                font-family: 'Asuicons';
            }
            &.leaflet-pm-icon-rectangle::before {
                content: '\34';
                font-family: 'Asuicons';
            }
            &.leaflet-pm-icon-polygon::before {
                content: '\35';
                font-family: 'Asuicons';
            }
            &.leaflet-pm-icon-circle::before {
                content: '\38';
                font-family: 'Asuicons';
            }
            &.leaflet-pm-icon-cut::before {
                content: '\e6c7';
                font-family: 'Linearicons';
            }
            &.leaflet-pm-icon-edit::before {
                content: '\e9be';
                font-family: 'Linearicons';
            }
            &.leaflet-pm-icon-delete::before {
                content: '\e680';
                font-family: 'Linearicons';
            }
        }

        // START перестановка кнопок.
        // Самый не красивый способ.
        // Пока не будет решение через js ставить нужный порядок.
        border: 0;
        &:nth-child(1) {
            order: 3;
            border-radius: 0;
            border-bottom: 1px solid $map-toolbox-border-light;
        }
        &:nth-child(2) {
            order: 6;
            border-bottom: 1px solid $map-toolbox-border-light;
            border-right: 1px solid $map-toolbox-border-light;
        }
        &:nth-child(3) {
            order: 5;
            border-bottom: 1px solid $map-toolbox-border-light;
            border-right: 1px solid $map-toolbox-border-light;
        }
        &:nth-child(4) {
            order: 4;
            border-right: 1px solid $map-toolbox-border-light;
        }
        &:nth-child(5) {
            order: 2;
            border-bottom: 1px solid $map-toolbox-border-light;
        }
        &:nth-child(6) {
            order: 1;
            border-radius: 0 0 3px 0;
        }
        &:nth-child(7) {
            order: 7;
        }
        &:nth-child(8) {
            order: 8;
        }
    }
}

.nc-leaflet-pm-btn {
    position: absolute;
    left: 191px;
    top: 108px;
    z-index: 1;
    height: 22px;
    width: 54px;
    border-radius: 0 0 2px 2px;
    border-bottom: 0;
    background: $map-toolbox-arrow-bg;
    text-align: center;
    color: $map-toolbox-icon-color;
    cursor: pointer;
    transition: top 0.2s linear;

    &::before {
        content: '\e939';
        font-family: 'Linearicons';
        font-size: 19px;
        line-height: 22px;
    }

    &--tiny {
        top: 0;

        &::before {
            content: '\e93a';
        }

        ~ .leaflet-control-container .leaflet-pm-toolbar.leaflet-bar {
            top: -108px;
        }
    }
}
