.nc-row {
    &_sticky {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: var(--ion-background-color, #fff);
        background-position: left 0 bottom -4px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAHBAMAAADzDtBxAAAAD1BMVEUAAAAAAAAAAAAAAAAAAABPDueNAAAABXRSTlMUCS0gBIh/TXEAAAAaSURBVAjXYxCEAgY4UIICBmMogMsgFLtAAQCNSwXZKOdPxgAAAABJRU5ErkJggg==);
        background-repeat: repeat-x;
    }
}
