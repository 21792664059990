.nc-cell {
    &_small {
        min-width: 100px;
    }
    &_middle {
        min-width: 200px;
    }
    &_large {
        min-width: 400px;
        max-width: 100%;
    }

    &_html {
        min-width: 200px;
    }

    &_date {
        &:not(.nc-table__cell_caption) {
            font-size: 14px;
        }
    }

    &_npp {
        min-width: 55px;

        &:not(.nc-table__cell_caption) {
            font-size: 14px;
        }
    }

    &_indicator {
        padding: 0;
        width: 7px;
        max-width: 7px;
        min-width: 7px;

        &:not(.nc-table__cell_caption) {
            border-left: 7px solid
                var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
        }
    }

    &_icon {
        width: 56px;
        // max-width: 56px;
        min-width: 56px;
        height: 56px;
        // background: #f7f7f7;
        // border-radius: 50%;

        &:not(.nc-table__cell_caption) {
            text-align: center;
        }
    }
}
