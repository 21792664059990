.nc-list {
    .nc-item {
        margin: 16px 0;
    }

    &_horizontal {
        .nc-item {
            display: inline-block;
            margin: var(--nc-list-h-item-margin, 0 20px 0 0);

            // @include media-query-screen($min: 320px, $max: 1024px) {
            //     display: block;
            // }
        }
    }
}
