.nc-alert {
    &_selectable {
        .alert-message {
            user-select: all;

            &::selection {
                background-color: var(--ion-text-color);
                color: var(--ion-background-color);
            }
        }
    }
}
