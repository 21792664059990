$map-overlay-bg: var(--ion-color-step-850, #313541) !default;
$map-label-bg: var(--ion-background-color, #ffffff) !default;
$map-label-sign-bg: var(--ion-color-step-850, #313541) !default;
$map-label-sign-color: var(--ion-background-color, #ffffff) !default;
$map-label-color: var(--ion-color-step-850, #313541) !default;
$map-label-address-color: var(--ion-color-step-850, #313541) !default;
$map-label-telemetry-sign-bg: transparent !default;
$map-label-telemetry-sign-img-border: var(--ion-color-step-850, #313541) !default;
$map-label-telemetry-txt-accent-bg: var(--ion-color-primary, #3955f1) !default;
$map-label-telemetry-txt-accent-color: var(--ion-background-color, #ffffff) !default;
$map-label-simple-txt-color: var(--ion-color-step-600, #6a728c) !default;
$map-marker-move: #8251f1 !default;
$map-marker-move-corner: var(--ion-background-color, #ffffff) !default;
$map-marker-move-icon-bg: var(--ion-background-color, #ffffff) !default;

.nc-map {
    height: 100%;

    &-wrapper,
    &-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1; // for IE

        img {
            max-width: none;
        }
    }
    &-overlay {
        // background: transparentize($map-overlay-bg, 0.2);
    }

    &-container {
        height: var(--nc-map-container-height, 400px);
        align-items: stretch;

        &_full {
            // height: calc(100vh - var(--nci-toolbar-min-height, 56px) - var(--nci-paginator-height, 64px));
            height: 100%;
        }

        &.nci-item {
            align-items: stretch;
        }

        &__list {
            max-width: var(--nc-map-list, 30%);
            overflow-y: auto;
        }

        nc-geojson-map {
            // position: relative;
            height: 100%;
            width: 100%;
        }
    }

    &__label {
        position: relative;
        height: 90px;
        width: 261px;
        padding: 3px;
        background: $map-label-bg;

        &::after {
            content: '';
            position: absolute;
            bottom: -20px;
            right: 35px;
            border: 10px solid transparent;
            border-top-color: $map-label-bg;
        }

        .nc-label__sign {
            float: right;
            width: 84px;
            height: 84px;
            margin-left: 15px;
            background: $map-label-sign-bg;
            text-align: center;
            color: $map-label-sign-color;

            &::after {
                content: '';
                position: absolute;
                bottom: -15px;
                right: 35px;
                z-index: 1;
                border: 10px solid transparent;
                border-top-color: $map-label-sign-bg;
            }

            .icon,
            .asuicon {
                display: block;
                line-height: 84px;
                font-size: 38px;
            }

            .icon_truck {
                margin-top: -5px;
            }
        }
        .nc-label__txt {
            position: absolute;
            text-align: left;
            right: 87px;
            left: 0;
            padding: 0 15px 15px 15px;
            top: 0;
            background-color: $map-label-bg;
        }

        .nc-label__title {
            margin: 11px 0 8px;
            // font-family: 'Roboto Condensed', sans-serif;
            font-weight: 300;
            font-size: 1.75rem;
            line-height: 2.0833rem;
            color: $map-label-color;
        }
        .nc-label__number {
            font-weight: 400;
        }

        .nc-label__address {
            max-width: 390px;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.1667rem;
            color: $map-label-address-color;

            &__txt {
                overflow: hidden;
            }

            .icon {
                float: left;
                padding-right: 10px;
                font-size: 27px;
                line-height: 27px;
            }
        }

        // types
        &_telemetry {
            height: 86px;
            width: 267px;
            padding: 4px;

            .nc-label__sign {
                float: left;
                margin: 0 13px 0 0;
                width: 77px;
                height: 77px;
                background: $map-label-telemetry-sign-bg;

                img {
                    display: block;
                    padding: 1px;
                    border: 2px solid $map-label-telemetry-sign-img-border;
                }

                &::after {
                    bottom: -15px;
                    left: 33px;
                    right: auto;
                }
            }
            .nc-label__txt {
                left: 83px;
                right: 0;
                padding: 10px 13px 13px;

                .nc-txt_accent {
                    display: inline-block;
                    min-width: 54px;
                    padding: 0 6px;
                    margin: 0 6px;
                    border-radius: 3px;
                    background: $map-label-telemetry-txt-accent-bg;
                    font-weight: 700;
                    font-size: 1rem;
                    line-height: 2rem;
                    color: $map-label-telemetry-txt-accent-color;
                }

                .nc-txt_bold {
                    font-weight: 700;
                }
            }
            .nc-label__title {
                margin: 0;
                // font-family: 'Roboto', sans-serif;
                font-weight: 900;
                font-size: 1rem;
                line-height: 1.6667rem;
                text-transform: uppercase;
            }

            &::after {
                right: auto;
                left: 33px;
            }
        }

        &_simple {
            height: auto;

            &::after {
                right: auto;
                left: 35px;
            }

            .nc-label__txt {
                position: static;
                padding: 15px;
                font-weight: 700;
                font-size: 1rem;
                line-height: 1.1667rem;
                color: $map-label-simple-txt-color;

                .icon,
                .asuicon,
                .enticon {
                    float: left;
                    padding-right: 10px;
                    font-size: 27px;
                    line-height: 27px;
                }
            }
        }
    }
}

.nc-map-marker-move {
    position: relative;
    transition: transform 0.2s linear;
    transform-origin: 22px center;

    .nc-marker-move__back {
        width: 41px;
        height: 41px;
        border-radius: 50% 50% 50% 0;
        background: $map-marker-move;
        transition: transform 0.2s linear;

        &::before {
            content: '';
            position: absolute;
            bottom: 2px;
            left: 2px;
            border-left: 2px solid $map-marker-move-corner;
            border-bottom: 2px solid $map-marker-move-corner;
            border-right: 2px solid transparent;
            border-top: 2px solid transparent;
        }
    }

    .icon,
    .asuicon {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 41px;
        height: 41px;
        border: 3px solid $map-marker-move;
        border-radius: 50%;
        background: $map-marker-move-icon-bg;
        font-size: 20px;
        line-height: 35px;
        text-align: center;
        color: $map-marker-move;
    }
}

.nc-map-legend {
    display: flex;

    .nc-item {
        padding: 0 5px;

        .nc-txt {
            padding: 0 5px;
            font-size: 11px;
            line-height: 1.4;
            text-align: center;
            color: $map-toolbox-arrow-color;
        }

        .nc-value {
            height: 5px;
        }
    }
}
