ion-popover {
    &.nc-modal__menu::part(content) {
        --min-width: 375px;
        --width: auto;
        --max-width: 500px;
        bottom: 50px !important;
        top: auto !important;
    }

    &::part(content) {
        padding: 10px;
    }
}
