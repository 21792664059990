.nc-file {
    position: relative;

    ion-input[type='file'],
    .native-input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        opacity: 0;
        cursor: pointer;
    }
}
