.nc-color-todo {
    --nc-color-base: var(--nc-color-todo);
    --nc-color-base-rgb: var(--nc-color-todo-rgb);
    --nc-color-contrast: var(--nc-color-todo-contrast);
    --nc-color-contrast-rgb: var(--nc-color-todo-contrast-rgb);
    --nc-color-shade: var(--nc-color-todo-shade);
    --nc-color-tint: var(--nc-color-todo-tint);
}

.nc-color-active {
    --nc-color-base: var(--nc-color-active);
    --nc-color-base-rgb: var(--nc-color-active-rgb);
    --nc-color-contrast: var(--nc-color-active-contrast);
    --nc-color-contrast-rgb: var(--nc-color-active-contrast-rgb);
    --nc-color-shade: var(--nc-color-active-shade);
    --nc-color-tint: var(--nc-color-active-tint);
}

.nc-color-pending {
    --nc-color-base: var(--nc-color-pending);
    --nc-color-base-rgb: var(--nc-color-pending-rgb);
    --nc-color-contrast: var(--nc-color-pending-contrast);
    --nc-color-contrast-rgb: var(--nc-color-pending-contrast-rgb);
    --nc-color-shade: var(--nc-color-pending-shade);
    --nc-color-tint: var(--nc-color-pending-tint);
}

.nc-color-passive {
    --nc-color-base: var(--nc-color-passive);
    --nc-color-base-rgb: var(--nc-color-passive-rgb);
    --nc-color-contrast: var(--nc-color-passive-contrast);
    --nc-color-contrast-rgb: var(--nc-color-passive-contrast-rgb);
    --nc-color-shade: var(--nc-color-passive-shade);
    --nc-color-tint: var(--nc-color-passive-tint);
}

.nc-color-work {
    --nc-color-base: var(--nc-color-work);
    --nc-color-base-rgb: var(--nc-color-work-rgb);
    --nc-color-contrast: var(--nc-color-work-contrast);
    --nc-color-contrast-rgb: var(--nc-color-work-contrast-rgb);
    --nc-color-shade: var(--nc-color-work-shade);
    --nc-color-tint: var(--nc-color-work-tint);
}

.nc-color-danger {
    --nc-color-base: var(--nc-color-danger);
    --nc-color-base-rgb: var(--nc-color-danger-rgb);
    --nc-color-contrast: var(--nc-color-danger-contrast);
    --nc-color-contrast-rgb: var(--nc-color-danger-contrast-rgb);
    --nc-color-shade: var(--nc-color-danger-shade);
    --nc-color-tint: var(--nc-color-danger-tint);
}
