.nch-pointer {
    cursor: pointer;
}

.nch-events-none {
    pointer-events: none;
}

.nch-text-right {
    text-align: right;
}

.nch-text-center {
    text-align: center;
}

.nch-text-left {
    text-align: left;
}

.nch-flex-row {
    display: flex;
    flex-direction: row;
}

.nch-flex-column-r {
    display: flex;
    flex-direction: column-reverse;
}

.nch-flex-column {
    display: flex;
    flex-direction: column;
}

.nch-shrink {
    flex-shrink: 0;
}

.nch-margin_null {
    margin: 0;
}

.nch-align-self-start {
    align-self: flex-start;
}

.nch-align-self-center {
    align-self: center;
}

.nch-align-self-end {
    align-self: flex-end;
}

.nch-align-center {
    align-items: center;
}

.nch-align-end {
    align-items: flex-end;
}

.nch-align-stretch {
    align-items: stretch;
}

.nch-flex-start {
    justify-content: flex-start;
}

.nch-flex-end {
    justify-content: flex-end;
}

.nch-flex-center {
    justify-content: center;
}

.nch-flex-between {
    justify-content: space-between;
}

.nch-opacity {
    @for $i from 1 through 9 {
        &_#{$i} {
            opacity: calc(#{$i} / 10);
        }
    }
}

.nch-sticky {
    position: sticky;
    top: var(--nch-sticky-top, 0);
    z-index: 1;
    background: var(--ion-background-color);
}

.nch-full-width {
    width: 100%;
}

.nch-no-min-width {
    min-width: 0;
}
