$leaflet-contextmenu-shadow: rgba(0, 0, 0, 0.4) !default;
$leaflet-contextmenu-bg: #fff !default;
$leaflet-contextmenu-item-border: transparent !default;
$leaflet-contextmenu-item-color: #222 !default;
$leaflet-contextmenu-item-over-bg: #f4f4f4 !default;
$leaflet-contextmenu-item-over-border: #f0f0f0 !default;
$leaflet-contextmenu-item-disabled-over-border: transparent !default;
$leaflet-contextmenu-separator-border: #ccc !default;

.leaflet-contextmenu {
    display: none;
    box-shadow: 0 1px 7px $leaflet-contextmenu-shadow;
    border-radius: 4px;
    padding: 4px 0;
    background-color: $leaflet-contextmenu-bg;
    cursor: default;
    user-select: none;
}

.leaflet-contextmenu a.leaflet-contextmenu-item {
    display: block;
    color: $leaflet-contextmenu-item-color;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none;
    padding: 0 12px;
    border-top: 1px solid $leaflet-contextmenu-item-border;
    border-bottom: 1px solid $leaflet-contextmenu-item-border;
    cursor: default;
    outline: none;
}

.leaflet-contextmenu a.leaflet-contextmenu-item-disabled {
    opacity: 0.5;
}

.leaflet-contextmenu a.leaflet-contextmenu-item.over {
    background-color: $leaflet-contextmenu-item-over-bg;
    border-top: 1px solid $leaflet-contextmenu-item-over-border;
    border-bottom: 1px solid $leaflet-contextmenu-item-over-border;
}

.leaflet-contextmenu a.leaflet-contextmenu-item-disabled.over {
    background-color: inherit;
    border-top: 1px solid $leaflet-contextmenu-item-disabled-over-border;
    border-bottom: 1px solid $leaflet-contextmenu-item-disabled-over-border;
}

.leaflet-contextmenu-icon {
    margin: 2px 8px 0 0;
    width: 16px;
    height: 16px;
    float: left;
    border: 0;
}

.leaflet-contextmenu-separator {
    border-bottom: 1px solid $leaflet-contextmenu-separator-border;
    margin: 5px 0;
}
