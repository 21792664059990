.nci-paginator {
    align-items: center;
    background: var(--ion-background-color);

    ion-segment-button {
        --padding-start: 0;
        --padding-end: 0;
        --border-radius: 4px;
        --border-width: 2px;
        --border-color: var(--color);
        --border-style: solid;
        --color: var(--color-checked);
        --indicator-height: 0;
        --nci-margin-start: 7px;
        --nci-margin-end: 7px;
        // max-width: 100px;
        min-width: 50px;
        height: calc(
            var(--nci-paginator-height) - var(--border-width) * 2 - var(--nci-margin-start) - var(--nci-margin-end)
        );
        margin: var(--nci-margin-start);
        font-size: 16px;
        font-weight: 700;

        .dark-theme.md &,
        .dark-theme.ios & {
            --color: var(--ion-text-color);
            --border-color: var(--ion-color-step-150, #d9d9d9);
        }

        &.segment-button-checked {
            --border-color: transparent;
            background: transparentize(#1772c6, 0.9);

            .dark-theme.md &,
            .dark-theme.ios & {
                background: transparentize(#ffffff, 0.9);
            }
        }
    }
}
