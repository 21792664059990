.nci-list {
    display: block;
    contain: content;
    list-style-type: none;

    padding-top: 8px;
    padding-bottom: 8px;

    &_visible {
        contain: inherit;
    }
}
