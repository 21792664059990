.nc-ws-page {
    &__section {
        padding: 20px 60px;

        & > * {
            margin-left: 0;
            margin-right: 0;
        }

        @include media-query-device($device: smartphone) {
            padding: 20px;
        }
    }
    &__side {
        padding: 20px;
    }
}

.nc-page_only-iframe {
    iframe {
        height: 100%;
        width: 100%;
        margin: 0;
        border: 0;
    }
}
