.nc-service-page {
    display: flex; // height: 100vh;

    .nc-dummy {
        align-items: center;
    }

    .nc-figure {
        height: auto;
    }
}
