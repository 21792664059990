ion-row[align-items-end] {
    align-items: flex-end;
}
ion-row[align-items-center] {
    align-items: center;
}

[align-self-center] {
    align-self: center;
}

[align-self-end] {
    align-self: flex-end;
}

ion-footer {
    z-index: 100;
}
ion-header {
    z-index: 101;

    // ion-toolbar {
    //     ion-button {
    //         text-transform: none;
    //     }
    // }
}

ion-col > ion-card,
ion-col > .nci-card {
    height: calc(100% - 20px);
}

ion-label > a {
    position: relative;
    z-index: 3;
}

.label-floating.sc-ion-label-md-h,
.item-textarea.label-floating.sc-ion-label-md-h,
.item-textarea .label-floating.sc-ion-label-md-h {
    transform: translate3d(0, 23px, 0);
}
.item-has-focus.label-floating.sc-ion-label-md-h,
.item-has-focus .label-floating.sc-ion-label-md-h,
.item-has-placeholder.label-floating.sc-ion-label-md-h,
.item-has-placeholder .label-floating.sc-ion-label-md-h,
.item-has-value.label-floating.sc-ion-label-md-h,
.item-has-value .label-floating.sc-ion-label-md-h,
.nc-has-mask.label-floating.sc-ion-label-md-h,
.nc-has-mask .label-floating.sc-ion-label-md-h {
    transform: translate3d(0, 30%, 0) scale(0.75);
}
.item-label-floating.item-has-placeholder.sc-ion-input-md-h:not(.item-has-value),
.item-label-floating.item-has-placeholder:not(.item-has-value) .sc-ion-input-md-h {
    opacity: 1;
}

ion-toolbar ion-button > ion-label {
    @include media-query-screen($min: 320px, $max: 960px) {
        display: none;
    }
}

ion-chip {
    min-height: 32px;
    height: auto;

    ion-icon {
        flex: 0 0 1em;
    }
}

ion-modal::part(backdrop) {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);

    .dark-theme.md &,
    .dark-theme.ios & {
        background: var(--ion-text-color);
    }
}

ion-footer ion-toolbar {
    --padding-start: 16px;
    --padding-end: 16px;
}

.sc-ion-buttons-md-s ion-button:not(.button-round),
.sc-ion-buttons-ios-s ion-button:not(.button-round),
ion-thumbnail {
    --border-radius: 4px;
}

ion-card app-input-date .nc-input-group,
.nci-card app-input-date .nc-input-group {
    padding: 0 16px;
}

.ion-text-center {
    text-align: center;
}

ion-select::part(text) {
    white-space: normal;
}

ion-item {
    --background-focused: transparent;
    --padding-start: var(--nci-item-padding-start, 16px);
    --padding-end: var(--nci-item-padding-end, 16px);
}

.popover-viewport {
    ion-icon[slot='start'] {
        margin-inline-end: 16px;
    }
}

ion-toolbar {
    --min-height: var(--nci-toolbar-min-height, 56px);
}

.item-label-floating .sc-ion-textarea-md-h {
    margin-top: 0;
}

.nci-toast {
    &::part(container) {
        flex-direction: column;
    }
}

ion-icon,
ion-item::part(detail-icon) {
    pointer-events: none;
}

div[slot] {
    ion-icon {
        font-size: 24px;
    }
}
