@mixin everySecond() {
    @include media-query-screen($min: 1100px, $max: 1550px) {
        @content;
    }
    @include media-query-screen($min: 768px, $max: 992px) {
        @content;
    }
}

@mixin everyThird() {
    @include media-query-screen($min: 992px, $max: 1100px) {
        @content;
    }
    @include media-query-screen($min: 320px, $max: 768px) {
        @content;
    }
}

.nc-map-timeline {
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: 10px;
    padding-bottom: 10px;
    background: white;
    z-index: 1000;

    .nc-timeline {
        &__labels {
            display: flex;
            margin-top: -25px;
            padding: 0 14px;
        }

        &__label {
            flex: 0 0 4.1666%;
            font-size: 10px;
            text-align: left;

            @include everySecond() {
                flex: 0 0 8.323%;

                &:nth-child(2n) {
                    display: none;
                }
            }
            @include everyThird() {
                flex: 0 0 12.498%;

                &:nth-child(2n),
                &:nth-child(3n) {
                    display: none;
                }
            }
        }

        &__chart {
            display: block;
            width: 100%;
            padding: 0 14px;
            margin-bottom: -20px;
        }
    }
}
