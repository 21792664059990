.nci-filter {
    --side-max-width: 400px;

    .nc-ws-page__side {
        padding: 16px;
        height: 100%;

        > ion-list {
            min-height: 100%;
        }
    }

    ion-item {
        --padding-start: 8px;
        --inner-padding-end: 8px;

        > [slot='start'] {
            margin-right: 16px;
            margin-top: 0;
            margin-bottom: 0;
        }

        > [slot='end'] {
            margin-left: 8px;
        }

        // ion-label.sc-ion-label-md-h,
        // ion-label.sc-ion-label-ios-h {
        //     // white-space: normal;
        //     max-width: 100%;
        // }

        // .label-floating.sc-ion-label-md-h,
        // .label-floating.sc-ion-label-ios-h {
        //     transform: translate3d(0,  23px,  0);

        //     + ion-select {
        //         transform: translate3d(0,  -8px,  0);
        //     }
        // }

        &.item-has-value {
            // .label-floating.sc-ion-label-md-h,
            // .label-floating.sc-ion-label-ios-h {
            //     transform: translate3d(0,  50%,  0) scale(0.75);

            //     + ion-select {
            //         transform: none;
            //     }
            // }

            &.item-select.label-floating.sc-ion-label-md-h,
            &.item-select .label-floating.sc-ion-label-md-h {
                transform: translate3d(0, 30%, 0) scale(0.75);

                + ion-select {
                    transform: translate3d(0, 0, 0);
                }
            }
        }

        .item-select.label-floating.sc-ion-label-md-h,
        .item-select .label-floating.sc-ion-label-md-h {
            transform: translate3d(0, 23px, 0);

            + ion-select {
                transform: translate3d(0, -15%, 0);
            }
        }
    }

    &__item {
        ion-icon[slot='start'] {
            margin-right: 16px;
            padding: 8px;
        }
    }

    // .nc-input-group {
    //     margin: 10px 0;
    // }
}
