.nc-paginator {
    display: flex;
    justify-content: center;
    align-items: center;

    &__item {
        --background: transparent;
        --border-width: 2px;
        --border-style: solid;
        --border-color: var(--ion-color-primary);
        --box-shadow: none;
        --color: var(--ion-color-primary);

        width: 100%;

        ion-input {
            --padding-start: 0;
            text-align: center;
        }

        .dark-theme.md &,
        .dark-theme.ios & {
            --border-color: var(--ion-color-step-150, #d9d9d9);
            --color: var(--ion-text-color);
        }

        &--active {
            --border-color: transparent;
            background: transparentize(#1772c6, 0.9);

            .dark-theme.md &,
            .dark-theme.ios & {
                background: transparentize(#ffffff, 0.9);
            }
        }
    }
}
