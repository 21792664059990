$map-toolbar-icon-color: #939497 !default;
$map-toolbar-icon-active-color: var(--ion-color-step-850, #313541) !default;
$map-toolbox-select-border: var(--ion-color-step-150, #dbdbdb) !default;
$map-toolbar-item-hover-bg: var(--ion-color-step-150, #dbdbdb) !default;
$map-toolbar-item-icon-color: #8c8e91 !default;
$map-toolbar-item-hover-icon-color: #3f3f3f !default;
$map-toolbar-item-active-bg: var(--ion-color-step-50, #f7f7f7) !default;
$map-toolbar-time-bg: #1d2028 !default;
$map-toolbar-timeaxis-bg: #f2f3f4 !default;
$map-toolbar-context-menu-bg: var(--ion-color-step-50, #fafafa) !default;
$map-toolbar-context-menu-border: var(--ion-color-step-150, #dbdbdb) !default;
$map-toolbar-context-menu-label: var(--ion-color-step-850, #313541) !default;
$map-toolbar-context-menu-label-bg: var(--ion-color-step-50, #f7f7f7) !default;
$map-toolbar-context-menu-checked-bg: var(--ion-color-step-50, #f7f7f7) !default;
$map-toolbar-context-menu-checked-color: var(--ion-color-secondary) !default;
$map-toolbar-chb-label-gradient: var(--ion-color-secondary) !default;
$map-toolbar-selec-label-color: var(--ion-color-step-850, #313541) !default;
$map-toolbar-select-label-item-picked-bg: transparent !default;
$map-toolbar-select-item-color: var(--ion-color-step-850, #313541) !default;
$map-toolbar-select-item-hover-color: var(--ion-color-step-900, #1e2129) !default;
$map-toolbar-select-item-picked-color: var(--ion-color-step-900, #1e2129) !default;
$map-toolbar-select-item-picked-hover-color: var(--ion-text-color, #000000) !default;
$map-toolbar-select-item-picked-icon: var(--ion-color-step-850, #202020) !default;
$map-toolbar-select-item-icon-color: var(--ion-background-color, #ffffff) !default;
$map-toolbar-select-item-hover-icon-color: var(--ion-color-secondary) !default;
$map-toolbar-select-list-bg: var(--ion-background-color, #ffffff) !default;
// $map-toolbar-select-list-shadow:					transparentize(var(--ion-text-color-rgb), .88) !default;
$map-toolbar-select-border-gradient-start: var(--ion-color-secondary) !default;
$map-toolbar-select-border-gradient-end: transparent !default;
$map-toolbar-select-dark-picked-bg: transparent !default;
$map-toolbar-select-dark-picked-icon-bg: var(--ion-color-secondary) !default;
$map-toolbar-select-dark-picked-icon-color: var(--ion-background-color, #ffffff) !default;
$map-toolbar-time-shadow: var(--ion-text-color, #000000) !default;
$map-toolbar-time-timeaxis-major-line: var(--ion-background-color, #ffffff) !default;
$map-toolbar-time-timeaxis-tick-bg: transparent !default;
$map-toolbar-time-current-time-bg: var(--ion-background-color, #ffffff) !default;
$map-toolbar-time-bar: var(--ion-background-color, #ffffff) !default;
$map-toolbar-time-bar-shadow: var(--ion-text-color, #000000) !default;
$map-toolbar-time-point-bg: var(--ion-background-color, #ffffff) !default;
$map-toolbar-time-point-border: var(--ion-color-step-850, #313541) !default;
$map-toolbar-time-zoom-bg: var(--ion-color-step-150, #dbdbdb) !default;
$map-toolbar-time-zoom-color: var(--ion-text-color, #000000) !default;
$map-toolbar-chb-label-bg: transparent !default;
$map-toolbar-btn-dark-gradient: var(--ion-color-secondary) !default;
$map-toolbar-datepicker: var(--ion-color-step-850, #313541) !default;
$map-toolbar-datepicker-day-selected: var(--ion-background-color, #ffffff) !default;

.nc-map-toolbar {
    display: flex;
    flex-direction: column;
    // @include flex-column();
    flex-wrap: wrap;
    align-content: flex-start;
    height: 108px;
    transition: transform 0.2s linear;

    .nc-toolbar__item {
        position: relative;
        height: 54px;
        width: 54px;

        // toolbar_layers
        &.nc-toolbar__item_layers .nc-context-menu {
            z-index: 2;
            left: 27px;
            width: 220px;
            background: $map-toolbar-context-menu-bg;

            hr {
                height: 0;
                border-top: 1px solid $map-toolbar-context-menu-border;
                margin: 5px -10px 5px -6px;
            }

            label {
                height: 27px;
                line-height: 27px;
                // font-family: 'Roboto', sans-serif;
                font-size: 13px;
                font-weight: 300;
                white-space: nowrap;
                color: $map-toolbar-context-menu-label;
                cursor: pointer;

                &:hover {
                    background: $map-toolbar-context-menu-label-bg;
                }

                .icon,
                .asuicon {
                    display: inline-block;
                    vertical-align: bottom;
                    width: 27px;
                    height: 27px;
                    margin-right: 10px;
                    background: $map-toolbar-context-menu-label;
                    border-bottom: 1px solid $map-toolbox-arrow-bg;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: $map-toolbox-icon-color;
                }

                &:last-child {
                    .icon,
                    .asuicon {
                        border-bottom: 0;
                    }
                }
            }

            input[type='radio'],
            input[type='checkbox'] {
                display: none;

                + span {
                    display: block;
                    padding-right: 10px;
                    text-transform: none;
                }

                &:checked + {
                    span {
                        background: $map-toolbar-context-menu-checked-bg;

                        > .icon {
                            color: $map-toolbar-context-menu-checked-color;
                        }
                    }
                }
            }
        }
    }

    &__btn,
    .nc-btn {
        position: relative;
        width: 54px;
        height: 54px;
        outline: 0;
        padding: 0;
        border: 0;
        font-size: 20px;
        text-align: center;
        text-decoration: none;
        color: $map-toolbar-icon-color;
        cursor: pointer;

        .icon,
        .asuicon,
        .enticon {
            position: relative;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
            background: $map-toolbox-bg;
            border-bottom: 1px solid $map-toolbox-border-light;
            border-right: 1px solid $map-toolbox-border-light;
            line-height: 54px;
        }

        .nc-txt {
            position: absolute;
            left: 53px;
            top: 0;
            z-index: 2;
            height: 54px;
            padding: 0 10px;
            background: $map-toolbox-bg;
            font-size: 1rem;
            line-height: 54px;
            white-space: nowrap;
            text-transform: uppercase;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s ease-out;

            pointer-events: none;
        }

        // types
        &.nc-btn_dark {
            color: $map-toolbox-icon-color;

            .icon,
            .asuicon {
                background-color: $map-toolbox-control-bg;
                background-clip: padding-box;
                @include gradient-border($map-toolbar-btn-dark-gradient, $map-toolbox-control-bg);
                background-size:
                    0 4px,
                    100% 0px;
                transition: background 0.3s ease-out;

                border-color: $map-toolbox-border;
            }

            .nc-txt {
                background: $map-toolbox-control-bg;
            }
        }

        // states
        &:hover {
            .nc-txt {
                opacity: 1;
                visibility: visible;
            }
        }

        &.nc-btn--active {
            color: $map-toolbar-icon-active-color;

            &.nc-btn_dark {
                color: $map-toolbox-icon-color;

                .icon,
                .asuicon,
                .enticon {
                    background-size:
                        100% 4px,
                        100% 4px;
                }
            }
        }

        .leaflet-right & {
            float: right;
        }
    }

    &__search {
        clear: both;
    }

    &__chb {
        position: relative;
        background: $map-toolbox-control-bg;
        border-left: 1px solid $map-toolbox-border;
        border-bottom: 1px solid $map-toolbox-border;

        &:nth-child(1),
        &:nth-child(2) {
            border-left: 0;
        }

        label {
            display: block;
            width: 54px;
            height: 53px;
            background-color: $map-toolbar-chb-label-bg;
            background-clip: padding-box;
            @include gradient-border($map-toolbar-chb-label-gradient, $map-toolbox-control-bg);
            background-size:
                0 4px,
                100% 0px;
            background-position:
                center calc(100% - 1px),
                center calc(100% - 1px);
            transition: background 0.3s ease-out;

            font-size: 20px;
            text-align: center;
            line-height: 53px;
            font-weight: 400;
            color: $map-toolbox-icon-color;
            cursor: pointer;
        }

        input {
            display: none;
        }

        .nc-txt {
            position: absolute;
            left: 53px;
            top: 0;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 54px;
            width: 176px;
            background: $map-toolbox-control-bg;
            font-size: 1rem;
            line-height: 14px;
            text-transform: uppercase;
            text-align: left;
            color: $map-toolbox-icon-color;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s ease-out;

            pointer-events: none;
        }

        // &.nc-chb_enable label::before {
        //     content: '\e610';
        //     font-family: 'Linearicons';
        // }

        // &.nc-chb_preview label::before {
        //     content: '\e6a5';
        //     font-family: 'Linearicons';
        // }

        // states
        &:hover {
            .nc-txt {
                opacity: 1;
                visibility: visible;
            }
        }

        &:hover input + label,
        input:checked + label,
        &.nc-chb--active label {
            background-size:
                100% 4px,
                100% 4px;
        }
    }

    &__select {
        position: relative;

        > .icon,
        > .asuicon,
        // > .enticon,
        > [class^='meicon'] {
            position: relative;
            z-index: 1;
            display: block;
            width: 54px;
            height: 54px;
            line-height: 54px;
            background: $map-toolbox-bg;
            border-bottom: 1px solid $map-toolbox-border-light;
            border-right: 1px solid $map-toolbox-border-light;
            font-size: 20px;
            text-align: center;
            text-decoration: none;
            color: $map-toolbar-icon-color;
            cursor: pointer;
        }

        .nc-select {
            &__label {
                position: absolute;
                left: 53px;
                top: 0;
                z-index: 2;

                display: flex;
                flex-direction: column;
                justify-content: center;
                // @include flex-column(column, center);
                height: 54px;
                min-width: 176px;
                padding: 0 10px;
                background: $map-toolbox-bg;
                font-size: 1rem;
                line-height: 14px;
                text-transform: uppercase;
                color: $map-toolbar-selec-label-color;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.3s ease-out;

                pointer-events: none;

                .nc-select__item_picked,
                .nc-select__item_picked:hover {
                    background: $map-toolbar-select-label-item-picked-bg;
                    color: $map-toolbar-select-item-color;
                }

                .nc-btn {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    pointer-events: all;

                    .icon,
                    .asuicon,
                    .enticon {
                        line-height: 54px;
                    }
                }
            }
            &__list {
                margin: 0;
                padding: 0;
                list-style: none;
                // @extend .nc-list-box-zero;
                display: none;
                position: absolute;
                z-index: 1001;
                left: 0;
                right: auto;
                top: 54px;
                height: auto;
                width: 229px;
                background: $map-toolbar-select-list-bg;
                // box-shadow: 0px 0px 9px $map-toolbar-select-list-shadow;

                &::after {
                    display: none;
                }
            }
            &__item {
                display: flex;
                align-items: center;
                position: relative;
                height: 33px;
                padding-left: 13px;
                border-top: 1px solid $map-toolbox-select-border;
                opacity: 1;
                visibility: visible;
                vertical-align: middle;
                font-weight: 300;
                font-size: 13px;
                color: $map-toolbar-select-item-color;

                &:first-child {
                    border-top: 0;
                }

                .icon,
                .asuicon {
                    width: 33px;
                    height: 33px;
                    // margin-left: -11px;
                    margin-right: 13px;
                    line-height: 33px;
                    font-size: 19px;
                    color: $map-toolbar-item-icon-color;
                }

                .nc-btn {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                }

                &_picked {
                    display: none;
                    background: $map-toolbar-item-active-bg;

                    font-size: 13px;
                    line-height: 15px;
                    font-weight: 300;
                    text-transform: none;
                    white-space: nowrap;
                    color: $map-toolbar-select-item-picked-color;

                    .icon,
                    .asuicon,
                    .meicon {
                        color: $map-toolbar-select-item-picked-icon;
                    }

                    &:hover {
                        color: $map-toolbar-select-item-picked-hover-color;

                        .icon,
                        .asuicon {
                            color: $map-toolbar-select-item-picked-icon;
                        }
                    }
                }

                &:hover {
                    background: $map-toolbar-item-hover-bg;
                    color: $map-toolbar-select-item-hover-color;

                    .icon,
                    .asuicon {
                        color: $map-toolbar-item-hover-icon-color;
                    }
                }
            }
        }

        &::after {
            content: '';
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            height: 4px;
            width: 229px;

            background-clip: padding-box;
            @include gradient-border(
                $map-toolbar-select-border-gradient-start,
                $map-toolbar-select-border-gradient-end
            );
            background-size:
                0 4px,
                100% 0px;
            background-position:
                0 100%,
                0 100%;
            transition: background 0.3s ease-out;
        }

        // States
        &:hover {
            .nc-select__label {
                opacity: 1;
                visibility: visible;
            }

            &::after {
                display: block;
            }
        }

        &.nc-select--shown {
            .nc-select__list {
                display: block;
            }
            .nc-select__label {
                opacity: 1;
                visibility: visible;
            }

            &::after {
                display: block;
                background-size:
                    100% 4px,
                    100% 4px;
            }
        }

        &.nc-select--active {
            > [class^='meicon'] {
                color: $map-toolbar-icon-active-color;
            }

            .nc-select__item_picked {
                display: block;
            }
        }

        // types
        &_dark {
            > [class^='meicon'],
            .nc-select__label {
                background: $map-toolbox-control-bg;
                color: $map-toolbox-icon-color;
            }

            > [class^='meicon'] {
                border-bottom: 1px solid $map-toolbox-border;
                border-right: 1px solid $map-toolbox-border;
            }

            .nc-select {
                &__label {
                    text-transform: none;
                    font-weight: 700;

                    .nc-select__item_picked,
                    .nc-select__item_picked:hover {
                        color: $map-toolbox-icon-color;
                    }
                }
                &__list {
                    background: var(--ion-color-step-50, #fafafa);
                }
                &__item {
                    height: 27px;
                    border-top: 0;
                    line-height: 26px;

                    .icon,
                    .asuicon,
                    .enticon {
                        width: 27px;
                        height: 27px;
                        border-bottom: 1px solid $map-toolbox-arrow-bg;
                        background: $map-toolbox-control-bg;
                        font-size: 17px;
                        line-height: 26px;
                        color: $map-toolbar-select-item-icon-color;
                    }

                    &:hover {
                        background: $map-toolbar-item-active-bg;

                        .icon,
                        .asuicon {
                            color: $map-toolbar-select-item-hover-icon-color;
                        }
                    }

                    &:last-child {
                        .icon,
                        .asuicon,
                        .enticon {
                            border-bottom: 0;
                        }
                    }

                    &--picked {
                        background: $map-toolbar-select-dark-picked-bg;

                        .icon,
                        .asuicon,
                        .enticon {
                            background: $map-toolbar-select-dark-picked-icon-bg;
                            color: var(--ion-background-color, #ffffff);
                        }

                        &:hover {
                            background: $map-toolbar-item-active-bg;

                            .icon,
                            .asuicon,
                            .enticon {
                                background: $map-toolbar-select-dark-picked-icon-bg;
                                color: $map-toolbar-select-dark-picked-icon-color;
                            }
                        }
                    }
                }
            }

            &::before {
                content: '';
                display: none;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;
                height: 4px;
                width: 53px;

                background-clip: padding-box;
                @include gradient-border(
                    $map-toolbar-select-border-gradient-start,
                    $map-toolbar-select-border-gradient-end
                );
                background-size:
                    0 4px,
                    100% 0px;
                background-position:
                    0 100%,
                    0 100%;
                transition: background 0.3s ease-out;
            }

            &.nc-select--active {
                > [class^='meicon'] {
                    color: $map-toolbox-icon-color;
                }

                &::before {
                    display: block;
                    background-size:
                        100% 4px,
                        100% 4px;
                }
            }
        }

        &.nc-select_few {
            &.nc-select--active {
                .nc-select__item_picked {
                    display: none;
                }
            }
        }
    }

    &__time {
        position: relative;
        // box-shadow: 0px 0px 5px transparentize($map-toolbar-time-shadow, 0.78);
        width: 216px;

        .nc-datepicker-wrap {
            // box-shadow: 0px 0px 9px transparentize($map-toolbar-time-shadow, .88);
        }

        .nc-timeaxis {
            left: 0;

            &-wrap {
                padding: 0;
                height: 54px;
                background: $map-toolbar-time-bg;
            }
            &_major {
                top: 7px;
                height: 39px;
                width: 100%;

                &::after {
                    content: '';
                    position: absolute;
                    top: 20px;
                    left: 1px;
                    right: 1px;
                    height: 1px;
                    background: $map-toolbar-time-timeaxis-major-line;
                }
            }
            &__tick {
                width: 0;
                background: $map-toolbar-time-timeaxis-tick-bg;
                border-left: 1px dashed transparentize($map-toolbar-timeaxis-bg, 0.8);
            }
        }

        .nc-current-time {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            cursor: e-resize;

            &::before {
                content: '';
                position: absolute;
                top: 1px;
                bottom: 1px;
                left: 0;
                right: 5px;
                // background: linear-gradient(270deg, $map-toolbar-time-current-time-bg -5.09%, transparentize($map-toolbar-time-current-time-bg, 1) 100%);
                opacity: 0.4;
                cursor: default;
            }

            .nc-time__bar {
                position: relative;
                float: right;
                width: 1px;
                margin-right: 3px;
                height: 100%;
                background: $map-toolbar-time-bar;
                // box-shadow: 0px 0px 4px transparentize($map-toolbar-time-bar-shadow, .75);

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -2px;
                    width: 3px;
                    border-top: 2px solid $map-toolbar-time-bar;
                    border-left: 1px solid transparent;
                    border-right: 1px solid transparent;
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: -2px;
                    width: 3px;
                    border-bottom: 2px solid $map-toolbar-time-bar;
                    border-left: 1px solid transparent;
                    border-right: 1px solid transparent;
                }
            }

            .nc-time__point {
                position: absolute;
                top: 24px;
                right: 0;
                width: 7px;
                height: 7px;
                border: 1px solid $map-toolbar-time-point-border;
                border-radius: 50%;
                background: $map-toolbar-time-point-bg;
            }
        }

        .nc-map-toolbar__btn {
            float: left;
            border-top: 1px solid $map-toolbox-border-light;

            &.nc-btn_plus,
            &.nc-btn_minus {
                font-size: 13px;
                font-weight: 700;
            }
        }

        .nc-zoom {
            position: absolute;
            // z-index: 1;
            bottom: 16px;
            left: 42px;
            width: 23px;
            height: 23px;
            border-radius: 50%;
            background: $map-toolbar-time-zoom-bg;
            font-size: 11px;
            line-height: 23px;
            text-align: center;
            color: $map-toolbar-time-zoom-color;
        }
    }

    .nc-datepicker {
        &-wrap {
            position: absolute;
            top: 0;
            left: 100%;
            width: 91px;
            height: 54px;
            background: $map-toolbar-datepicker;

            .nc-arrow-dd {
                // @include arrow-dd($trip-control-btn-color, 4px, 4px, 4px);
            }
        }

        .nc-datepicker__content .nc-calendar {
            box-shadow: none;
        }

        .nc-input-group {
            height: 54px;
            padding: 0;

            > .icon,
            .nc-nowrap > .icon,
            &__label {
                display: none;
            }

            .nc-nowrap {
                background: none;
            }

            &__field {
                width: 100%;
                height: 54px;
                padding: 0;
                background: none;
                // font-family: "Roboto Mono";
                text-align: center;
                line-height: 54px;
            }
        }

        &__content {
            top: 54px;
        }

        .nc-calendar__header,
        .nc-calendar__day_selected,
        .nc-calendar__month_selected,
        .nc-calendar__year_selected,
        .nc-timepicker__scoreboard,
        .nc-timepicker__caption,
        .nc-hours__item--picked,
        .nc-minutes__item:hover,
        .nc-minutes__item--picked,
        .nc-minutes__item--picked .nc-item__value {
            background: $map-toolbar-datepicker;
        }

        .nc-calendar__day_amid span {
            // background: transparentize($map-toolbar-datepicker, .9);
        }

        .nc-calendar__day_current,
        .nc-calendar__day_current:hover,
        .nc-calendar__day_current::after {
            // color: darken(desaturate($map-toolbar-datepicker, 10.68), 3.73);
        }

        .nc-scoreboard__item--picked .nc-item__value span {
            // color: darken(desaturate($map-toolbar-datepicker, 10.90), 4.12);
        }

        .nc-calendar__day_selected.nc-calendar__day_current,
        .nc-calendar__day_selected.nc-calendar__day_current::after {
            color: var(--ion-background-color, #ffffff);
        }

        .nc-calendar__footer .nc-calendar__btn {
            color: $map-toolbar-datepicker;
        }
    }

    &__date {
        z-index: 1;

        .nc-datepicker-wrap {
            position: relative;
            left: 0;
        }
    }

    ion-item.nc-map-toolbar__date {
        --inner-padding-end: 0;
        --min-height: 54px;
        width: 108px;
    }

    .nc-btn_toggle {
        display: block;
        position: absolute;
        z-index: 1;
        top: 108px;
        left: 0;

        height: 22px;
        width: 54px;
        border-radius: 0 0 3px 3px;
        border: 0;
        background: $map-toolbox-arrow-bg;
        text-align: center;
        line-height: 22px;
        font-size: 19px;
        color: $map-toolbox-icon-color;
        cursor: pointer;

        &.nc-hide {
            top: 54px;
        }

        &::before {
            content: '\e939';
            font-family: 'Linearicons';
        }

        &--closed {
            &::before {
                content: '\e93a';
            }
        }

        .leaflet-bottom & {
            top: auto;
            bottom: 108px;
            border-radius: 3px 3px 0 0;

            &::before {
                content: '\e93a';
            }

            &--closed {
                &::before {
                    content: '\e939';
                }
            }
        }

        .nc-map-wrapper_trip & {
            width: 108px;
        }
    }

    .nc-context-menu {
        right: auto;
        left: 0;
        width: 300px;

        &__item {
            height: 27px;
            border-bottom: 1px solid $map-toolbox-border-light;

            &:last-child,
            &:last-child .nc-color {
                border-bottom: 0;
            }
        }

        .nc-color {
            height: 26px;
            width: 7px;
        }

        .nc-checkbox {
            overflow: hidden;
            padding: 0 5px;

            &__label {
                display: flex;
                align-items: center;
                height: 27px;
                line-height: 1.2;

                &::before {
                    bottom: 0;
                    margin: auto;
                }
                &::after {
                    bottom: 3px;
                    margin: auto;
                }
            }
        }
    }

    // types
    &_monitoring {
        .nc-btn_toggle {
            width: 108px;
        }
    }

    .leaflet-top &--tiny {
        transform: translateY(-108px);
    }

    .leaflet-bottom & {
        &--tiny {
            transform: translateY(108px);
        }
    }
}
