@mixin gradient-border($start-color: $grey-3-14, $end-color: $grey-primary, $start-percent: 0%, $end-percent: 100%) {
    background-image:
        -webkit-linear-gradient(top, $start-color $start-percent, $start-color $end-percent),
        -webkit-linear-gradient(top, $end-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
    background-image:
        -o-linear-gradient(top, $start-color $start-percent, $start-color $end-percent),
        -o-linear-gradient(top, $end-color $start-percent, $end-color $end-percent); // Opera 12
    background-image: linear-gradient(to bottom, $start-color $start-percent, $start-color $end-percent),
        linear-gradient(to bottom, $end-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
    // background-position: center bottom, center -webkit-calc(100% - 1px);
    background-position:
        center bottom,
        center calc(100% - 1px);
    background-repeat: no-repeat;
}

@mixin gradient-border-left($start-color, $end-color, $start-percent: 0%, $end-percent: 100%) {
    background-image:
        -webkit-linear-gradient(left, $start-color $start-percent, $start-color $end-percent),
        -webkit-linear-gradient(left, $end-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
    background-image:
        -o-linear-gradient(left, $start-color $start-percent, $start-color $end-percent),
        -o-linear-gradient(top, $end-color $start-percent, $end-color $end-percent); // Opera 12
    background-image: linear-gradient(to right, $start-color $start-percent, $start-color $end-percent),
        linear-gradient(to right, $end-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
    background-position:
        left bottom,
        left bottom;
    background-position:
        left bottom,
        left bottom;
    background-repeat: no-repeat;
}

@mixin linear-gradient-bottom(
    $first-color: $color-white,
    $first-position: 0%,
    $second-color: $color-white,
    $second-position: 26%,
    $third-color: $color-white,
    $third-position: 100%
) {
    background-image: -webkit-linear-gradient(
        bottom,
        $first-color $first-position,
        $second-color $second-position,
        $third-color $third-position
    ); // Safari 5.1-6, Chrome 10+
    background-image: -o-linear-gradient(
        bottom,
        $first-color $first-position,
        $second-color $second-position,
        $third-color $third-position
    ); // Opera 12
    background-image: linear-gradient(
        to top,
        $first-color $first-position,
        $second-color $second-position,
        $third-color $third-position
    ); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
    background-repeat: no-repeat;
}

@mixin linear-gradient($direction, $start-color, $start-percent, $end-color, $end-percent) {
    background-image: -webkit-linear-gradient(
        $direction,
        $start-color $start-percent,
        $start-color $end-percent
    ); // Safari 5.1-6, Chrome 10+
    background-image: -o-linear-gradient(
        $direction,
        $start-color $start-percent,
        $start-color $end-percent
    ); // Opera 12
    background-image: linear-gradient(
        $direction,
        $start-color $start-percent,
        $start-color $end-percent
    ); // Standard, IE10, Firefox
}
