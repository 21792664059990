.nc-entcard {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    height: calc(100% - 20px);
    min-height: 140px;

    margin-bottom: 20px;
    margin-right: 20px;
    border: 1px solid var(--ion-color-step-150, #d9d9d9);
    border-radius: 4px;
    background: var(--ion-item-background, var(--ion-background-color, #fff));
    cursor: pointer;

    > *:not(*:first-child) {
        flex-shrink: 0;
    }

    > *:first-child {
        height: 100%;
    }

    &__bottom {
        // padding-top: 16px;
        flex-shrink: 0;
        border-top: 1px solid var(--ion-color-step-150, #d9d9d9);

        .nc-figure {
            max-width: 46px;
            height: 30px;
            margin-right: 8px;
        }

        .swiper-wrapper {
            height: 30px;
        }

        .swiper-pagination {
            display: none;
        }
    }

    ion-item {
        &:first-child {
            --border-radius: 4px 4px 0 0;
        }

        &:last-child {
            --border-radius: 0 0 4px 4px;

            &:first-child {
                --border-radius: 4px;
            }
        }
    }

    > .item.sc-ion-label-md-h,
    > .item.sc-ion-label-ios-h,
    > .item {
        ion-icon:not([slot='icon-only']) {
            margin-right: 16px;
        }

        .sc-ion-label-md-h,
        .sc-ion-label-ios-h {
            font-size: 14px;

            small {
                font-size: 12px;
            }
        }
    }

    &:hover {
        // --background-focused: var(--ion-item-background, var(--ion-background-color, #fff));
        border-color: var(--nc-color-active);
    }

    &.nch-flex-center {
        justify-content: center;

        > ion-item {
            --min-height: 100%;
            height: 100%;
        }
    }
}
