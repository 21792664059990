// $palette-shadow:							transparentize($color-black, .88) !default;
$palette-panel-head-bg: var(--ion-color-step-50, #f0f0f0) !default;
$palette-panel-head-btn-icon-color: var(--ion-color-step-500, #828282) !default;
$palette-panel-body-bg: transparent !default;
$palette-panel-title-minor-color: var(--ion-color-step-850, grey) !default;
$palette-head-bg: #1d2028 !default;
$palette-head-color: var(--ion-color-step-150, #dbdbdb) !default;
$palette-head-icon: #8c9aad !default;
$palette-body-bg: var(--ion-color-step-150, #dbdbdb) !default;
$palette-item-border: var(--ion-color-step-150, #dbdbdb) !default;
$palette-item-hover-shadow: var(--ion-text-color, #000000) !default;
$palette-item-active-bg: var(--ion-color-primary) !default;
$palette-item-active-color: var(--ion-background-color, #ffffff) !default;
$palette-list-bg: var(--ion-background-color, #ffffff) !default;
$palette-list-btn-bg: var(--ion-background-color, #ffffff) !default;
$palette-list-btn-icon: var(--ion-color-step-500, #828282) !default;
$palette-list-item-bg: var(--ion-background-color, #ffffff) !default;
$palette-series-bg: var(--ion-background-color, #ffffff) !default;
$palette-btn-group-bg: var(--ion-color-step-50, #f0f0f0) !default;
$palette-btn-bg: transparent !default;
$palette-btn-save: var(--ion-color-secondary) !default;
$palette-btn-cancel: var(--ion-color-warning) !default;
$palette-btn-del: var(--ion-color-danger) !default;

.nc-palette {
    position: absolute;
    z-index: 1;
    top: 145px;
    left: 23px;
    width: 216px;
    // box-shadow: 0px 0px 9px $palette-shadow;
    // font-family: 'Roboto', sans-serif;
    cursor: default;

    &_middle {
        width: 500px;
    }

    .nc-panel {
        &__head {
            height: 33px;
            padding-left: 13px;
            border: 0;
            background: $palette-panel-head-bg;

            > .nc-btn {
                width: 33px;
                height: 33px;
                line-height: 33px;

                .icon {
                    font-size: 11px;
                    line-height: 33px;
                    color: $palette-panel-head-btn-icon-color;
                }
            }
        }

        &__title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // @include flex-column(column, center);
            width: calc(100% - 33px);
            height: 33px;
            padding-right: 5px;

            @include text-ellipsis();
            font-size: 10px;
            line-height: 1.2;

            .nc-title_minor {
                margin-top: 0;
                text-transform: none;
                color: $palette-panel-title-minor-color;
            }
        }

        &__body {
            background: $palette-panel-body-bg;
        }
    }

    &__head {
        height: 23px;
        border-radius: 3px 3px 0 0;
        background: $palette-head-bg;
        // cursor: move;

        .nc-btn {
            position: absolute;
            top: 0;
            right: 5px;
            width: 23px;
            height: 23px;
            line-height: 23px;

            .icon {
                font-size: 11px;
                color: $palette-head-icon;
            }
        }
    }

    &__title {
        width: 100%;
        padding: 0 33px 0 13px;

        @include text-ellipsis();
        font-size: 10px;
        line-height: 23px;
        font-weight: 500;
        text-transform: uppercase;
        color: $palette-head-color;
    }

    &__body {
        background: $palette-body-bg;

        .nc-panel__body {
            overflow: visible;
        }
    }

    &__list {
        // @extend .nc-list-box-zero;
        margin: 0;
        padding: 0;
        list-style: none;

        .nc-item {
            float: left;
            position: relative;
            border-right: 1px solid $palette-item-border;

            .nc-btn {
                position: relative;
                z-index: 1;
                width: 53px;
                float: none;
                background: $palette-list-btn-bg;
                border-top: 1px solid $palette-item-border;

                .icon,
                .asuicon {
                    font-size: 20px;
                    line-height: 52px;
                    color: $palette-list-btn-icon;
                }

                .icon_truck {
                    margin-top: -7px;
                }
            }

            &__txt {
                display: none;
                position: absolute;
                left: 53px;
                top: 0;
                height: 54px;
                padding-right: 20px;
                border-right: 1px solid $palette-item-border;
                border-top: 1px solid $palette-item-border;
                background: $palette-list-item-bg;
                line-height: 54px;
                font-size: 13px;
                white-space: nowrap;
                pointer-events: none;
            }

            &::before {
                content: '';
                position: absolute;
                z-index: 1;
                right: 0;
                top: 0;
                height: 53px;
                border-top: 1px solid $palette-item-border;
                width: 22px;
                background: $palette-list-item-bg;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                .nc-btn,
                .nc-item__txt,
                &::before {
                    border-top: 0;
                }
            }
            &:nth-child(4n) {
                border-right: 0;

                .nc-btn {
                    width: 54px;
                }
            }
            &:nth-child(4n + 1) {
                border-left: 0;
            }

            &:hover {
                z-index: 2;
                // box-shadow: 0px 0px 21px transparentize($palette-item-hover-shadow, .85);

                .nc-item__txt {
                    display: block;
                    // box-shadow: 0px 0px 21px transparentize($palette-item-hover-shadow, .85);
                }
            }

            &--active {
                &::before {
                    background: $palette-item-active-bg;
                }

                .nc-btn,
                .nc-btn:hover,
                .nc-btn:focus,
                .nc-item__txt {
                    background: $palette-item-active-bg;

                    .icon,
                    .asuicon,
                    .enticon {
                        color: $palette-item-active-color;
                    }
                }

                .nc-item__txt {
                    color: $palette-item-active-color;
                }
            }
        }
    }

    .nc-field-series,
    .nc-data-series {
        padding: 10px 10px 0;
        background: $palette-series-bg;
    }

    .nc-btn-group {
        padding: 0 5px;
        background: $palette-btn-group-bg;

        .nc-btn {
            background: $palette-btn-bg;
            padding: 5px;

            .icon {
                margin-right: 4px;
            }

            &--disabled {
                -webkit-filter: grayscale(100%);
                -moz-filter: grayscale(100%);
                -ms-filter: grayscale(100%);
                -o-filter: grayscale(100%);
                filter: grayscale(100%);
                filter: grayscale(1); /* Firefox 4+ */
                filter: gray; /* IE 6-9 */
            }
        }

        .nc-btn_save {
            // color: darken(desaturate(adjust-hue($palette-btn-save, -14), 1.13), 9.02);

            &:hover {
                // color: darken(desaturate(adjust-hue($palette-btn-save, -14), 1.34), 2.75)
            }
        }

        .nc-btn_cancel {
            // color: darken(desaturate(adjust-hue($palette-btn-cancel, -14), 1.13), 9.02);

            &:hover {
                // color: darken(desaturate(adjust-hue($palette-btn-cancel, -14), 1.34), 2.75)
            }
        }

        .nc-btn_del {
            // color: darken(desaturate(adjust-hue($palette-btn-del, -14), 1.13), 9.02);

            &:hover {
                // color: darken(desaturate(adjust-hue($palette-btn-del, -14), 1.34), 2.75)
            }
        }
    }

    // маркированный список
    .nc-list {
        margin: 0;
        padding: 12px;
        list-style: none;
        background: $palette-list-bg;

        .nc-item {
            position: relative;
            margin: 0 0 15px;
            font-size: 11px;
            line-height: 13px;
            font-weight: 300;

            &:last-child {
                margin: 0;
            }

            .nc-checkbox {
                padding: 0 5px;

                &__label {
                    padding-top: 2px;
                    font-size: 11px;
                    font-weight: 300;
                    line-height: 1.2;
                }
            }
        }

        &_marked {
            .nc-item {
                padding-left: 21px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 14px;
                    height: 14px;
                }

                // @each $key, $value in $state-container-color-map {
                //     &_#{$key}::before {
                //         background: $value;
                //     }
                // }
            }
        }
    }
}
