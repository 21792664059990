/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

// @import "../node_modules/rpn-style/src/scss/main";

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './const.scss';
@import './theme/variables.scss';

.nc-modal__large::part(content) {
    height: calc(90% - 57px);
    top: 5%;
    width: 90%;
    max-width: 1440px;
    position: absolute;
}

.nc-modal__agreement::part(content) {
    height: 240px;
    top: 50%;
    width: 977px;
    max-width: 1440px;
    position: absolute;
    display: block;
    transform: translate(0, -50%);
}

.nc-modal-selection_wide {
    .popover-wrapper {
        --width: 90%;
        --max-width: 900px;
    }

    .alert-wrapper {
        --width: 90%;
        --max-width: 900px;
        width: auto;
    }
}

.nc-layout__2-cols {
    display: flex;

    > * {
        flex: 0 0 50%;
    }
}

.nc-layout__3-cols {
    display: flex;

    > * {
        flex: 0 0 33.33%;
    }
}

.nc-text-end {
    text-align: end;
}

.nc-text-center {
    text-align: center;
}

.nc-temp_pointable {
    cursor: pointer;
    border: 1px solid transparent;
    opacity: 0.75;

    &:hover {
        border-color: silver;
        opacity: 1;
    }
}

.alert-wrapper {
    .alert-checkbox-group,
    .alert-radio-group {
        max-height: 338px;

        /* хром, сафари */
        &::-webkit-scrollbar {
            display: block;
            width: 16px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            width: 14px;
            background-color: #bdbdbd;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
    }

    .alert-radio-label.sc-ion-alert-md {
        white-space: normal;
    }
}

.alert-checkbox-label.sc-ion-alert-md {
    white-space: normal;
}

.alert-tappable.sc-ion-alert-md {
    height: auto;
    contain: content;
}

ion-toast::part(container) {
    max-height: 98vh;
    overflow: auto;
    align-items: baseline;
}

// Исправление бага со вложенными ion-accordeon-group
ion-accordion-group > ion-accordion:last-of-type ion-accordion ion-item[slot='header'] {
    --border-width: 0px 0px 1px 0px;
}

ion-accordion-group > ion-accordion:last-of-type ion-accordion:last-of-type ion-item[slot='header'] {
    --border-width: 0px 0px 0px 0px;
}

.nc-erp-history__container {
    display: flex;
    position: relative;

    app-input-simple {
        flex-grow: 1;
    }

    .nc-erp-history__button {
        position: relative;
        display: flex;
        align-items: flex-end;
        z-index: 2;

        ion-button {
            --padding-end: 0;
            --padding-start: 0;
        }
    }
}

.popover-viewport {
    overflow: unset;
    u {
        text-underline-offset: 3px;
    }
}

// Дашборды

ion-card.nc-dashboard-component {
    margin-bottom: 1rem;
}

.nc-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        margin-bottom: 1rem;
        font-size: 20px;
        font-weight: 500;
        text-wrap: wrap;
    }
}

.nc-dashboard-toggle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.nc-dashboard-section-header {
    border-bottom: 1px solid $Border;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 8px;

    ion-card-title {
        font-weight: 700;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

h2.nc-dashboard-subtitle {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 24px;
}

.nc-no-grow {
    flex-grow: 0;
}

.nc-bordered-dashboard-container {
    border: 1px solid $Border;
    border-radius: 8px;

    & > h2 {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 24px;
    }
}

// .nc-full-height {
//     background-color: red;
//     display: flex;
//     color: green;
//     height: 100%;
// }
.nc-flex {
    display: flex;
}

.nc-full-width {
    width: 100%;
}

.nc-input-warning {
    ion-input {
        --background: var(--ion-color-warning);
    }
}

@media (max-width: 768px) {
    .nc-no-mobile-display {
        display: none;
    }
}

.nch-payment-modal {
    &::part(content) {
        position: absolute;
        left: calc(var(--side-max-width) + 40px);
        --width: 70%;
    }
    --height: 70%;
}

// Для исправления бага когда ионик ставит width: min-content
ion-item > ion-label {
    width: unset !important;
    min-width: 0;
}

.nci-item > ion-label {
    min-width: 0;
}

@media (max-width: 1024px) {
    .nch-payment-modal {
        --width: 100%;
        --height: 100%;
        &::part(content) {
            position: static;
            left: unset;
            --width: 100%;
        }
    }
}

// Для исправления бага когда ионик ставит width: min-content
ion-item > ion-label {
    width: unset !important;
    min-width: 0;
}

.nci-item > ion-label {
    min-width: 0;
}

.nc-input-simple {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--ion-color-step-150, rgba(0, 0, 0, 0.13));
    padding-left: 16px;
}

.nc-text-i {
    padding-bottom: 5px;
}

.red-text {
    color: red;
}
