.nc-overbox {
    position: relative;

    // ion-item:hover + ion-list {
    //     display: block;
    // }

    &__body {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 100;
        overflow-y: auto;
        background: #e7e7e7;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

        * {
            --ion-item-background: #e7e7e7;
        }
    }
}
